/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dataActions } from "../../../shared/store/data-Slice";
import { Route, Routes } from "react-router-dom";
import GrievanceLayout from "./GrievanceLayout";
import ResetPassword from "../../../components/common/auth/ResetPassword";
import LanguageChange from "../../../components/common/layout/LanguageChange";
import NewPassword from "../../../components/common/auth/NewPassword";
import VerifyEmail from "../../../components/common/auth/VerifyEmail";
import LoginPopup from "../../../components/common/auth/Login";
import RegisterPopup from "../../../components/common/auth/Register";
const Home = React.lazy(() => import("../../../pages/corporate/grievance/home/Home"));
const LandingPage = React.lazy(() => import("../../../pages/corporate/grievance/landingPage/LandingPage"));
const Login = React.lazy(() => import("../../../pages/corporate/grievance/auth/Login"));
const Register = React.lazy(() => import("../../../pages/corporate/grievance/auth/Register"));
const ResolveComplaint = React.lazy(() => import("../../../pages/corporate/grievance/home/ResolveComplaint"));
const Profile = React.lazy(() => import("../../../pages/passenger/profile/Profile"));
const NotFound = React.lazy(() => import("../../../pages/common/notFound/NotFound"));

const Grievance = () => {
    const isLogin: boolean = useSelector((state: any) => state.auth.isLogin);
    const loginOpen: boolean = useSelector((state: any) => state.data.loginOpen);
    const langOpen: boolean = useSelector((state: any) => state.data.langOpen);
    const registerOpen: boolean = useSelector((state: any) => state.data.registerOpen);
    const resetPasswordOpen: boolean = useSelector((state: any) => state.data.resetPasswordOpen);
    const newPasswordOpen: boolean = useSelector((state: any) => state.data.newPasswordOpen);
    const verifyEmailOpen: boolean = useSelector((state: any) => state.data.verifyEmailOpen);
    const dispatch = useDispatch();

    useEffect(() => {
        let host: string[] = window.location.hostname.split(".");
        if (host?.length > 0) {
            dispatch(dataActions.setTenant(host?.includes("www") ? host[1] : host[0]))
        }
    }, [window.location.hostname])

    useEffect(() => {
        if (langOpen || newPasswordOpen || verifyEmailOpen || resetPasswordOpen || loginOpen || registerOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [resetPasswordOpen, verifyEmailOpen, newPasswordOpen, langOpen, loginOpen, registerOpen]);

    return (
        <>
            {loginOpen && <LoginPopup type="passenger" />}
            {registerOpen && <RegisterPopup type="passenger" />}
            {resetPasswordOpen && <ResetPassword type="grievance" />}
            {newPasswordOpen && <NewPassword type="grievance" />}
            {verifyEmailOpen && <VerifyEmail />}
            {langOpen && <LanguageChange />}
            <Routes>
                {!isLogin ? <>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/:lang" element={<LandingPage />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/:lang/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/:lang/register" element={<Register />} />
                </> :
                    <Route element={<GrievanceLayout />}>
                        <Route path="/" element={<Home />} />
                        <Route path="/:lang" element={<Home />} />
                        <Route path="/all-resolve-complaint" element={<ResolveComplaint />} />
                        <Route path="/:lang/all-resolve-complaint" element={<ResolveComplaint />} />
                        <Route path="/register-new-complaint" element={<Home popup="register-new-complaint" />} />
                        <Route path="/:lang/register-new-complaint" element={<Home popup="register-new-complaint" />} />
                        <Route path="/assign-complaint/:id" element={<Home popup="assign-complaint" />} />
                        <Route path="/:lang/assign-complaint/:id" element={<Home popup="assign-complaint" />} />
                        <Route path="/complaint/:id" element={<Home popup="complaint" />} />
                        <Route path="/:lang/complaint/:id" element={<Home popup="complaint" />} />
                        <Route path="/resolve/complaint/:id" element={<ResolveComplaint popup="complaint" />} />
                        <Route path="/:lang/resolve/complaint/:id" element={<ResolveComplaint popup="complaint" />} />
                        <Route path="/resolve-complaint/:id" element={<Home popup="resolve-complaint" />} />
                        <Route path="/:lang/resolve-complaint/:id" element={<Home popup="resolve-complaint" />} />
                        <Route path="/refer-back-complaint/:id" element={<Home popup="refer-back-complaint" />} />
                        <Route path="/:lang/refer-back-complaint/:id" element={<Home popup="refer-back-complaint" />} />
                        <Route path="/:lang/profile" element={<Profile type="grievance" />} />
                        <Route path="/profile" element={<Profile type="grievance" />} />
                    </Route>
                }
                <Route path="/:lang/404" element={<NotFound />} />
                <Route path="/404" element={<NotFound />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </>
    );
};

export default Grievance;