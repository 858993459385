/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { dataActions } from "../../../shared/store/data-Slice";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useState, useRef, useEffect } from "react";
import { CheckEye, Eye, CloseBtn, Warn } from "../svg/AllSvg";
import useAxios from "../../../shared/hooks/use-axios";
import { url } from "../../../shared/utils/url";
import { authActions } from "../../../shared/store/auth-Slice";
import SecondaryBtn from "../form/SecondaryBtn";
import { useNavigate, useParams } from "react-router-dom";
import Captcha from "../form/Captcha";
import { numberOnPaste } from "../../../shared/utils/numberOnPaste";
import { errors } from "../../../shared/utils/errors";
import { Bounce, ToastContainer } from "react-toastify";

const Login = ({ type }: any) => {
    const tenant: string = useSelector((state: any) => state.data.tenant);
    const loginOpen: boolean = useSelector((state: any) => state.data.loginOpen);
    const lang: string = useSelector((state: any) => state.ui.lang);
    const [hide, setHide] = useState<boolean>(true);
    const [submit, setSubmit] = useState<boolean>(false);
    const [passFcs, setPassFcs] = useState<boolean>(false);
    const [captchaValue, setCaptchaValue] = useState<string>("")
    const [captchaInput, setCaptchaInput] = useState("");
    const [captchaError, setCaptchaError] = useState<string>("")
    const [captchaExpirationTime, setCaptchaExpirationTime] = useState(Date.now() + (5 * 60 * 1000));
    const dispatch = useDispatch();
    const { t } = useTranslation('common');
    const params = useParams();
    const navigate = useNavigate();
    const ref: any = useRef();

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target) && type !== "admin") {
                dispatch(dataActions.setLoginOpen(false));
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27 && type !== "admin") {
            dispatch(dataActions.setLoginOpen(false))
        }
    };

    const handlePassword = () => {
        setHide(!hide);
    };

    const formik: any = useFormik({
        enableReinitialize: true,
        initialValues: (type === "admin") ? {
            email: "",
            password: ""
        } : {
            phone: ""
        },
        validationSchema: (type === "admin") ?
            yup.object().shape({
                email: yup.string().email(t("auth.form.validation.wrong_email")).required(t("auth.form.validation.email")),
                password: yup.string().required(t("auth.form.validation.password")),
            }) :
            yup.object().shape({
                phone: yup.number().test('len', t("auth.form.validation.wrong_phone"), (val: any) => val && val.toString().length === 10).required(t("auth.form.validation.phone"))
            }),
        onSubmit: async (values: any) => {
            //submit
            if (!!!captchaValue) {
                setCaptchaError(t("auth.form.validation.captcha"))
                return;
            }

            if (captchaInput !== captchaValue) {
                setCaptchaError(t("auth.form.validation.match_captcha"));
                return;
            }

            try {
                setSubmit(true)
                let obj: any = {};
                if (type === "admin") {
                    obj.username = values.email
                    obj.password = values.password
                } else {
                    obj.mobile = values.phone
                    obj.type = "login"
                }

                let api: any = ""
                if (type === "admin") {
                    api = await useAxios.post(`/${(!!lang ? lang : "en") + url?.admin_login}`, obj);
                } else if (type === "recruitment") {
                    api = await useAxios.post(`/${(!!lang ? lang : "en") + url?.recruitment_send_otp}`, obj);
                } else {
                    api = await useAxios.post(`/${(!!lang ? lang : "en") + url?.login_otp}`, obj);
                }

                if (!!api) {
                    const res: any = api

                    if (res && res?.status === 200) {
                        if (type === "admin") {
                            dispatch(authActions.adminLogin());
                            let sessionTimeout: any = (new Date().getTime() + (86400000));
                            localStorage.setItem('adminSessionTimeout', sessionTimeout);
                            dispatch(authActions.adminDetail(res?.data?.data))
                            localStorage.setItem('admindetail', JSON.stringify(res?.data?.data));
                            localStorage.setItem('admintoken', res?.data?.data?.auth_token);
                            navigate(!!params?.lang ? `/${params?.lang}/admin` : "/admin")
                        } else {
                            localStorage.setItem("field_value", values?.phone)
                            localStorage.setItem("auth_type", "login")
                            localStorage.setItem("field", "mobile")
                            dispatch(dataActions.setVerifyEmailOpen(true))
                        }

                        dispatch(dataActions.setLoginOpen(false))
                        setSubmit(false)
                    }
                }
            } catch (error: any) {
                errors(error)
                setSubmit(false)
                console.error("error while login", error);
            }
        }
    });

    return (
        <>
            {loginOpen &&
                <div className={`fixed inset-0 z-[34] top-0 w-full h-full overflow-y-auto ${type === "admin" ? "bg-white dark:bg-bgDark" : "bg-[rgba(171,171,171,0.70)] backdrop-blur-[10px]"}`}>
                    <div className="flex justify-center items-center min-h-screen">
                        <div className={`relative m-[15px] mm:mx-auto dark:bg-bgDarkDull bg-white rounded-[15px] w-full xsm:w-[480px] h-full xlx:min-h-[600px] ${type === "admin" ? "shadow-[0px_8px_22px_-6px_rgba(24,39,75,0.12),0px_14px_64px_-4px_rgba(24,39,75,0.12)]" : "shadow-[0px_8px_22px_-6px_rgba(24,39,75,0.12)"}`} ref={ref}>
                            <ToastContainer
                                position="top-center"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light"
                                transition={Bounce}
                            />

                            <form className="flex flex-col relative w-full h-full items-start justify-center p-[20px] xlx:p-[24px] z-[1]">
                                <div className="flex w-full justify-between">
                                    <div className="flex flex-col w-full xlx:gap-[4px]">
                                        <h3 className="font-bold text-primaryText dark:text-white">{type === "admin" ? (tenant === "staff" ? t("auth.staffLoginTitle") : t("auth.HRLoginTitle")) : t("auth.loginTitle")}</h3>
                                        <h6 className="text-blackDark dark:text-textBlackDark font-medium">{type === "recruitment" ? t("auth.loginDesc") : t("auth.loginDesc1")}</h6>
                                    </div>
                                    {type !== "admin" && <button aria-label={t("aria_label.close")} title={t("aria_label.close")} className="rounded-full focus:shadow-[0_0_0_2px_#4e566d5e] hover:shadow-[0_0_0_2px_#4e566d5e] min-w-[23px] h-[23px] flex items-center justify-center bg-[#e4e4e4] dark:bg-[#4c4e5e5e] dark:focus:shadow-[0_0_0_2px_#ffffff5e] dark:hover:shadow-[0_0_0_2px_#ffffff5e]" type="button" onClick={() => dispatch(dataActions.setLoginOpen(false))}>
                                        <CloseBtn />
                                    </button>}
                                </div>

                                <div className="flex flex-col gap-[18px] pt-[20px] lg:pt-[33px] w-full">
                                    {type === "admin" ? <>
                                        <div className="flex flex-col gap-[4px]">
                                            <h6 className="text-blackDark dark:text-textBlackDark font-medium">{t("auth.form.field.email_or_phone")}</h6>
                                            <input className="rounded-[6px] hover:bg-white focus:bg-white  bg-[#F5F5F5] border border-[#D7D8DD] h-[35px] xlx:h-[40px] px-[15px] xlx:px-[17px] text-blackDull placeholder:text-blackDull text-sm leading-snug dark:bg-bgDark dark:hover:bg-bgDarkDull dark:focus:bg-bgDarkDull dark:border-[#454545] dark:text-textBlackDull placeholder:dark:text-textBlackDull" type="email" name="email" placeholder={t("auth.form.field.email_or_phone")} value={formik?.values?.email} onBlur={formik?.handleBlur} onChange={formik?.handleChange} autoComplete="off" autoFocus={true} />
                                            {formik?.touched?.email && formik?.errors?.email && <div className="flex gap-[6px] items-start"><Warn />
                                                <span className="text-danger dark:text-darkDanger text-xs font-medium">{formik?.errors?.email}</span>
                                            </div>}
                                        </div>

                                        <div className="flex flex-col gap-[4px]">
                                            <h6 className="text-blackDark dark:text-textBlackDark font-medium">{t("auth.form.field.password")}</h6>
                                            <div className={`rounded-[6px] border dark:border-[#454545] border-[#D7D8DD] h-[35px] xlx:h-[40px] w-full relative flex items-center ${passFcs ? "bg-white dark:bg-bgDarkDull" : "hover:bg-white bg-[#F5F5F5] dark:hover:bg-bgDarkDull dark:bg-bgDark"}`}>
                                                <input className="rounded-[6px] bg-transparent w-full text-blackDull text-sm leading-snug h-full pl-[15px] xlx:pl-[17px] pr-[40px] placeholder:text-blackDull dark:text-textBlackDull placeholder:dark:text-textBlackDull" type={hide ? "password" : "text"} name="password" placeholder={t("auth.form.field.password")} value={formik?.values?.password} onBlur={(e: any) => { formik?.handleBlur(e); setPassFcs(false) }} onChange={formik?.handleChange} autoComplete="off" onFocus={() => setPassFcs(true)} />

                                                <button type="button" className="absolute right-[10px] cursor-pointer h-full" onClick={handlePassword}>
                                                    {hide ? <CheckEye /> : <Eye />}
                                                </button>
                                            </div>
                                            {formik?.touched?.password && formik?.errors?.password && <div className="flex gap-[6px] items-start"><Warn />
                                                <span className="text-danger dark:text-darkDanger text-xs font-medium">{formik?.errors?.password}</span>
                                            </div>}
                                        </div>
                                    </> :
                                        <div className="flex flex-col gap-[4px]">
                                            <h6 className="text-blackDark dark:text-textBlackDark font-medium">{t("auth.form.field.phone")}</h6>
                                            <div className="flex items-center gap-[10px] w-full">
                                                <input className="placeholder:text-blackDull flex items-center justify-center px-[7px] w-[35px] xlx:w-[40px] h-[35px] xlx:h-[40px] rounded-[6px] hover:bg-white focus:bg-white  bg-[#F5F5F5] border border-[#D7D8DD] text-blackDull text-sm leading-snug dark:bg-bgDark dark:hover:bg-bgDarkDull dark:focus:bg-bgDarkDull dark:border-[#454545] dark:text-textBlackDull placeholder:dark:text-textBlackDull" defaultValue="+91" disabled autoComplete="off" />
                                                <input className="placeholder:text-blackDull w-full rounded-[6px] hover:bg-white focus:bg-white  bg-[#F5F5F5] border border-[#D7D8DD] h-[35px] xlx:h-[40px] px-[15px] xlx:px-[17px] text-blackDull text-sm leading-snug dark:bg-bgDark dark:hover:bg-bgDarkDull dark:focus:bg-bgDarkDull dark:border-[#454545] dark:text-textBlackDull placeholder:dark:text-textBlackDull" type="number" name="phone" placeholder={t("auth.form.field.phone")} autoComplete="off" value={formik?.values?.phone} onBlur={formik?.handleBlur} onChange={formik?.handleChange} onFocus={(e: any) => e.target.addEventListener("wheel", function (e: any) { e.preventDefault() }, { passive: false })}
                                                    onKeyDown={(e: any) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()} onPaste={(e: any) => numberOnPaste(e)} />
                                            </div>
                                            {formik?.touched?.phone && formik?.errors?.phone && <div className="flex gap-[6px] items-start"><Warn />
                                                <span className="text-danger dark:text-darkDanger text-xs font-medium">{formik?.errors?.phone}</span>
                                            </div>}

                                            <h6 className="text-blackDull dark:text-textBlackDull mt-[10px] font-normal mb-[50px]">{t("auth.verifyDesc")}</h6>
                                        </div>
                                    }

                                    <div className={`w-full ${type === "admin" && "pt-[20px]"}`}>
                                        <Captcha captchaValue={captchaValue} setCaptchaValue={setCaptchaValue} captchaError={captchaError} setCaptchaError={setCaptchaError} captchaExpirationTime={captchaExpirationTime} setCaptchaExpirationTime={setCaptchaExpirationTime} captchaInput={captchaInput} setCaptchaInput={setCaptchaInput} />
                                    </div>
                                </div>

                                <SecondaryBtn title={t("auth.loginBtn")} css="w-full flex items-center justify-center uppercase !rounded-[10px] mt-[30px] font-bold" onClick={formik?.handleSubmit} disabled={submit} />

                                <div className="flex items-center justify-center w-full pt-[15px] mb-[114px] xlx:mb-[110px]">
                                    {type !== "admin" && <div className="text-[#606060] dark:text-[#9b9b9b] text-sm leading-snug font-normal">{t("auth.no_account")} &nbsp;<button className="text-primary text-sm leading-snug font-semibold focus:underline hover:underline" type="button" onClick={() => { dispatch(dataActions.setRegisterOpen(true)); dispatch(dataActions.setLoginOpen(false)); }}>{t("auth.register")}</button></div>}
                                </div>
                            </form>

                            <div className="absolute top-0 left-0 right-0 bottom-0 flex items-end justify-end">
                                <img src={require("../../../assets/images/login-bg.png")} alt="login-bg" className="rounded-b-[15px] w-full" />
                            </div>
                        </div>
                    </div>
                </div>}
        </>
    );
};

export default Login;