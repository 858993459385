import { createSlice } from '@reduxjs/toolkit';

interface UIState {
    lang: string,
    theme: any,
    font: number
}

const initialState: UIState = {
    lang: "",
    theme: localStorage.getItem("theme") || "light",
    font: window.innerWidth <= 1400 ? 14 : 16
}

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        changeLang: (state, action) => {
            state.lang = action.payload;
        },
        changeTheme: (state, action) => {
            state.theme = action.payload;
        },
        changeFont: (state, action) => {
            state.font = action.payload;
        }
    }
});

export default uiSlice;
export const uiActions = uiSlice.actions;