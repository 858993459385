import { useDispatch, useSelector } from "react-redux";
import { dataActions } from "../../../shared/store/data-Slice";
import { CloseBtn, ImpNoticeIcon } from "../../common/svg/AllSvg";
import SecondaryBtn from "../../common/form/SecondaryBtn";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { url } from "../../../shared/utils/url";
import useAxios from "../../../shared/hooks/use-axios";
import { useTranslation } from "react-i18next";

const ApplyCondition = () => {
    const applyCondition: any = useSelector((state: any) => state.data.applyCondition);
    const lang: string = useSelector((state: any) => state.ui.lang);
    const [submit, setSubmit] = useState<boolean>(false)
    const { t } = useTranslation('common');
    const dispatch = useDispatch();
    const params = useParams();

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(dataActions.setApplyCondition(false))
        }
    };

    const apply = async () => {
        try {
            setSubmit(true)
            const res: any = await useAxios.post(`${(!!lang ? lang : "en") + url?.applyJob}`, { job: params?.jobId, apply: "true" });

            if (res && res.status === 200) {
                if (res?.data?.errors?.length > 0) {
                    let obj: any = {}
                    for (let i = 0; i < res?.data?.errors.length; i++) {
                        const el = res?.data?.errors[i];
                        if (el?.hasOwnProperty("category")) {
                            obj.category = el?.category
                        }
                        if (el?.hasOwnProperty("total_experience")) {
                            obj.total_experience = el?.total_experience
                        }
                        if (el?.hasOwnProperty("age")) {
                            obj.age = el?.age
                        }
                        if (el?.hasOwnProperty("qualification")) {
                            obj.qualification = el?.qualification
                        }
                    }

                    dispatch(dataActions.setApplyData(obj))
                }
                setSubmit(false)
                dispatch(dataActions.setApplyCondition(false))
                dispatch(dataActions.setApplyError(true))
            }
            if (res && res.status === 201) {
                dispatch(dataActions.setApplyData(res?.data))
                setSubmit(false)
                dispatch(dataActions.setApplyCondition(false))
                dispatch(dataActions.setApplySuccess(true))
            }
        } catch (error) {
            setSubmit(false)
            console.error("error while apply job", error);
        }
    }

    return (
        <>
            {applyCondition &&
                <div className="fixed inset-0 z-[34] overflow-y-hidden top-0 ">
                    <div
                        className="fixed inset-0 w-full h-full bg-[rgba(171,171,171,0.70)] backdrop-blur-[10px]" onClick={() => dispatch(dataActions.setApplyCondition(false))}
                    ></div>
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="relative m-[15px] mm:mx-auto bg-white dark:bg-bgDarkDull rounded-[10px] w-full md:w-[783px] h-auto shadow-[0px_8px_22px_-6px_rgba(24,39,75,0.12),0px_14px_64px_-4px_rgba(24,39,75,0.12))]">
                            <div className="w-full flex items-center justify-between py-[18px] px-[22px]">
                                <div className="flex items-center gap-[11px]">
                                    <ImpNoticeIcon />
                                    <h4 className="text-danger dark:text-darkDanger font-bold">{t("recruitment.home.confirmation")}</h4>
                                </div>
                                <button aria-label={t("aria_label.close")} title={t("aria_label.close")} className="rounded-full focus:shadow-[0_0_0_2px_#4e566d5e] hover:shadow-[0_0_0_2px_#4e566d5e] min-w-[23px] h-[23px] flex items-center justify-center bg-[#e4e4e4] dark:bg-[#4c4e5e5e] dark:focus:shadow-[0_0_0_2px_#ffffff5e] dark:hover:shadow-[0_0_0_2px_#ffffff5e]" autoFocus={true} onClick={() => dispatch(dataActions.setApplyCondition(false))}>
                                    <CloseBtn />
                                </button>
                            </div>

                            <div className="w-full h-[calc(100%-70px)] flex flex-col justify-between rounded-b-[10px]">
                                <div className="h-full overflow-y-scroll w-full flex flex-col gap-[24px] px-[13px]">
                                    <div className="bg-[#D9D9D9] bg-opacity-[0.21] px-[31px] py-[20px]">
                                        <h5 className="font-bold text-blackDull dark:text-textBlackDull">{t("recruitment.home.declaration")}</h5>

                                        <h6 className="font-semibold text-blackDull dark:text-textBlackDull pt-[20px]">{t("recruitment.home.decl_desc")}
                                        </h6>
                                    </div>
                                </div>

                                <div className="px-[31px] flex items-end justify-end py-[24px]">
                                    <SecondaryBtn title={t("recruitment.home.i_agree")} css="px-[46px] font-semibold" disabled={submit} onClick={apply} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
        </>
    );
};

export default ApplyCondition;