/* eslint-disable react-hooks/exhaustive-deps */
import { Outlet, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import LanguageChange from "../../../components/common/layout/LanguageChange";

const StaffLayout = () => {
    const langOpen: boolean = useSelector((state: any) => state.data.langOpen);
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    useEffect(() => {
        if (langOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [langOpen]);

    return (
        <>
            {langOpen && <LanguageChange />}
            <Outlet />
        </>
    );
};

export default StaffLayout;