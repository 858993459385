/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import RecruitmentLayout from "./RecruitmentLayout";
import { useDispatch, useSelector } from "react-redux";
import { dataActions } from "../../../shared/store/data-Slice";
const Home = React.lazy(() => import("../../../pages/corporate/recruitment/Home"));
const Vacancies = React.lazy(() => import("../../../pages/corporate/recruitment/Vacancies"));
const SubVacancies = React.lazy(() => import("../../../pages/corporate/recruitment/SubVacancies"));
const VacancieDetails = React.lazy(() => import("../../../pages/corporate/recruitment/VacancieDetails"));
const Profile = React.lazy(() => import("../../../pages/corporate/recruitment/Profile"));
const ProfileSuccess = React.lazy(() => import("../../../pages/corporate/recruitment/ProfileSuccess"));
const DeptJobs = React.lazy(() => import("../../../pages/corporate/recruitment/DeptJobs"));
const ScreenReaderAccess = React.lazy(() => import("../../../pages/common/screenReaderAccess/ScreenReaderAccess"));
const SiteMap = React.lazy(() => import("../../../pages/common/sitemap/SiteMap"));
const MyProfile = React.lazy(() => import("../../../pages/corporate/recruitment/MyProfile"));
const NotFound = React.lazy(() => import("../../../pages/common/notFound/NotFound"));

const Recruitment = () => {
    const isLogin: boolean = useSelector((state: any) => state.auth.isLogin);
    const dispatch = useDispatch();

    useEffect(() => {
        let host: string[] = window.location.hostname.split(".");
        if (host?.length > 0) {
            dispatch(dataActions.setTenant(host?.includes("www") ? host[1] : host[0]))
        }
    }, [window.location.hostname])

    return (
        <>
            <Routes>
                <Route element={<RecruitmentLayout />}>
                    {isLogin && <>
                        <Route path="/create-profile" element={<Profile />} />
                        <Route path="/:lang/create-profile" element={<Profile />} />
                        <Route path="/:lang/applied-jobs" element={<MyProfile />} />
                        <Route path="/applied-jobs" element={<MyProfile />} />
                        <Route path="/:lang/profile" element={<MyProfile />} />
                        <Route path="/profile" element={<MyProfile />} />
                        <Route path="/apply/:jobId" element={<Profile />} />
                        <Route path="/:lang/apply/:jobId" element={<Profile />} />
                        <Route path="/profile-success" element={<ProfileSuccess />} />
                        <Route path="/:lang/profile-success" element={<ProfileSuccess />} />
                    </>}
                    <Route path="/" element={<Home />} />
                    <Route path="/:lang" element={<Home />} />
                    <Route path="/vacancies" element={<Vacancies />} />
                    <Route path="/:lang/vacancies" element={<Vacancies />} />
                    <Route path="/vacancies/:id" element={<SubVacancies />} />
                    <Route path="/:lang/vacancies/:id" element={<SubVacancies />} />
                    <Route path="/jobs/:deptId" element={<DeptJobs />} />
                    <Route path="/:lang/jobs/:deptId" element={<DeptJobs />} />
                    <Route path="/vacancies/:id/:jobId" element={<VacancieDetails />} />
                    <Route path="/:lang/vacancies/:id/:jobId" element={<VacancieDetails />} />
                    <Route path="/screen-reader-access" element={<ScreenReaderAccess type="recruitment" />} />
                    <Route path="/:lang/screen-reader-access" element={<ScreenReaderAccess type="recruitment" />} />
                    <Route path="/site-map" element={<SiteMap type="recruitment" />} />
                    <Route path="/:lang/site-map" element={<SiteMap type="recruitment" />} />
                </Route>
                <Route path="/:lang/404" element={<NotFound />} />
                <Route path="/404" element={<NotFound />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </>
    );
};

export default Recruitment;