/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dataActions } from "../../../shared/store/data-Slice";
import { AlterChangeFare, CloseSec, Dis, FareBg, JouFare, LinkArr, Reset, Warn } from "../../common/svg/AllSvg";
import SelectComponent from "../../common/form/SelectComponent";
import useAxios from "../../../shared/hooks/use-axios";
import { url } from "../../../shared/utils/url";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

const ClearIndicator = (props: any) => {
    const {
        children = <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="fill-[#cccccc] focus:fill-[#1A1F36] hover:fill-[#999999] cursor-pointer"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>,
        innerProps: { ref, ...restInnerProps },
    } = props;

    return (
        <div
            {...restInnerProps}
            ref={ref}
            onMouseDown={(e) => {
                e.stopPropagation(); // Prevent popup from closing
                props.clearValue();
            }}
        >
            {children}
        </div>
    );
};

const FareCalculator = () => {
    const fareOpen: boolean = useSelector((state: any) => state.data.fareOpen);
    const lang: string = useSelector((state: any) => state.ui.lang);
    const { t } = useTranslation('common');
    const [fromSts, setFromSts] = useState<any[]>([]);
    const [fromStOpen, setFromStOpen] = useState<any>(false);
    let [fromStPh, setFromStPh] = useState<any>(t("passenger.home.fare.form_station"));
    const [toSts, setToSts] = useState<any[]>([]);
    const [toStOpen, setToStOpen] = useState<any>(false);
    let [fromStSearch, setFromStSearch] = useState<any>('');
    let [toStSearch, setToStSearch] = useState<any>('');
    let [toStPh, setToStPh] = useState<any>(t("passenger.home.fare.to_station"));
    const [fromErr, setFromErr] = useState<boolean>(false)
    const [toErr, setToErr] = useState<boolean>(false)
    let [fromSt, setFromSt] = useState<any>(null)
    let [toSt, setToSt] = useState<any>(null)
    const [submit, setSubmit] = useState<boolean>(false)
    const [data, setData] = useState<any>(null);
    const ref: any = useRef();
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();

    const handleReset: any = () => {
        setFromStPh(t("passenger.home.fare.form_station"))
        setToStPh(t("passenger.home.fare.to_station"))
        setFromErr(false)
        setToErr(false)
        setFromSt(null)
        setToSt(null)
        setToStSearch("")
        setFromStSearch("")
        setData(null)
    }

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                dispatch(dataActions.setFareOpen(false));
                !!params?.lang ? navigate(`/${params?.lang}`) : navigate(`/`)
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    const handleFromInputChanged = (input: any, reason: any) => {
        if (
            reason.action === "set-value" ||
            reason.action === "input-blur" ||
            reason.action === "menu-close"
        ) {
            return;
        }
        const regex = /^[a-zA-Z0-9 ]*$/;

        if (regex.test(input) || input === '') {
            setFromStSearch(input);
            setFromStPh("")
            input !== fromSt?.label && setFromSt(null);
        }
    };

    const handleToInputChanged = (input: any, reason: any) => {
        if (
            reason.action === "set-value" ||
            reason.action === "input-blur" ||
            reason.action === "menu-close"
        ) {
            return;
        }
        const regex = /^[a-zA-Z0-9 ]*$/;

        if (regex.test(input) || input === '') {
            setToStSearch(input);
            input !== toSt?.label && setToSt(null);
            setToStPh("")
        }
    };

    const handleFromChange = (e: any) => {
        fromSt = e;
        setFromSt(fromSt);
        !!fromSt && setFromErr(false)
        setFromStOpen(false);
        !!fromSt ? setFromStSearch(e?.label) : setFromStSearch("")
    }

    const handleToChange = (e: any) => {
        toSt = e;
        setToSt(toSt);
        !!toSt && setToErr(false)
        setToStOpen(false);
        !!toSt ? setToStSearch(e?.label) : setToStSearch("");
    }

    const handleAlterChange = () => {
        const from: any = fromSt;
        const to: any = toSt;
        const fromSearch: any = fromStSearch;
        const toSearch: any = toStSearch;
        fromSt = to;
        setFromSt(fromSt);
        toSt = from;
        setToSt(toSt)
        !!to ? setFromStSearch(to?.label) : setFromStSearch(toSearch)
        !!from ? setToStSearch(from?.label) : setToStSearch(fromSearch);
        (!!!to && !!!toSearch) && setFromStPh(t("passenger.home.fare.form_station"));
        (!!!from && !!!fromSearch) && setToStPh(t("passenger.home.fare.to_station"));
    }

    const getFromStations = async () => {
        try {
            let apiUrl: any = ""
            if (!!fromStSearch) {
                apiUrl = await useAxios.get(`/${(!!lang ? lang : "en") + url?.searchStation}${fromStSearch}/`);
            } else {
                apiUrl = await useAxios.get(`/${(!!lang ? lang : "en") + url?.searchStation}all/`);
            }

            if (!!apiUrl) {
                const res: any = apiUrl;
                if (res && res?.status === 200) {
                    const arr: any[] = res?.data?.length > 0 ? Array.isArray(res?.data) && res?.data?.map((item: any) => ({ label: item?.name, value: item?.code })) : [];
                    setFromSts(arr)

                    if (!!fromSt) {
                        let obj: any = arr?.find((item: any) => fromSt?.value === item?.value)
                        setFromSt(obj)
                        setFromStSearch(obj?.label)
                    }
                }
            }

        } catch (error) {
            console.error("error while feching stations", error);
        }
    }

    const getToStations = async () => {
        try {
            let apiUrl: any = ""
            if (!!toStSearch) {
                apiUrl = await useAxios.get(`/${(!!lang ? lang : "en") + url?.searchStation}${toStSearch}/`);
            } else {
                apiUrl = await useAxios.get(`/${(!!lang ? lang : "en") + url?.searchStation}all/`);
            }

            if (!!apiUrl) {
                const res: any = apiUrl;

                if (res && res?.status === 200) {
                    const arr: any[] = res?.data?.length > 0 ? Array.isArray(res?.data) && res?.data?.map((item: any) => ({ label: item?.name, value: item?.code })) : [];
                    setToSts(arr)

                    if (!!toSt) {
                        let obj: any = arr?.find((item: any) => toSt?.value === item?.value)
                        setToSt(obj)
                        setToStSearch(obj?.label)
                    }
                }
            }
        } catch (error) {
            console.error("error while feching stations", error);
        }
    }

    useEffect(() => {
        !!lang && getFromStations();
    }, [lang, fromStSearch])

    useEffect(() => {
        !!lang && getToStations();
    }, [lang, toStSearch])

    const getFareDetail = async (e: any) => {
        try {
            e.preventDefault()
            if (!!!fromSt && !!!toSt) {
                setFromErr(true)
                setToErr(true)
                return;
            }

            if (!!!fromSt) {
                setFromErr(true)
                return;
            }

            if (!!!toSt) {
                setToErr(true)
                return;
            }
            setSubmit(true)
            const res: any = await useAxios.post(`/${(!!lang ? lang : "en") + url?.travel_distance_time_fare}`, {
                "source": fromSt?.value,
                "destination": toSt?.value,
                "line_type": "normal"
            });

            if (res && res?.status === 200) {
                setData(res?.data)
                setSubmit(false)
            }
        } catch (error) {
            setData(null)
            setSubmit(false)
            console.error("error while feching travel fare detail", error);
        }
    }

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(dataActions.setFareOpen(false))
            !!params?.lang ? navigate(`/${params?.lang}`) : navigate(`/`)
        }
    };

    return (
        <>
            {fareOpen &&
                <div className="fixed inset-0 z-[34] top-0 w-full h-full overflow-y-auto bg-[rgba(216,216,216,0.60)] backdrop-blur-[15px]">
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="flex gap-[15px] mmm:flex-row flex-col m-[15px] mm:mx-auto w-full xlsm:w-[500px] mmm:w-fit relative" ref={ref}>
                            <div className="w-full xlsm:w-[500px] xlx:w-[541px] h-full relative">
                                <div style={{ background: "linear-gradient(90deg, #2F00FF66 -0.55%, #44B0FF66 22.86%, #8B44FF66 48.36%, #FF664466 73.33%, #EBFF7066 99.34%)" }} className="blur-[35px] absolute left-0 right-0 bottom-0 w-full xlsm:w-[505px] h-[524px] rotate-[180deg]"></div>

                                <div className="shadow-[0px_8px_22px_-6px_rgba(24,39,75,0.12) w-full h-full rounded-[20px] xlx:rounded-[25px]">
                                    <div className="w-full relative h-[58px] xlx:h-[66px] z-[2]">
                                        <div className="flex justify-end w-full h-full">
                                            <div className="w-[calc(100%-174px)] xlx:w-[calc(100%-200px)] rounded-t-[20px] xlx:rounded-t-[25px] h-full border-b-0 border-l-0 border dark:border-[#454545] border-[#E4E4E7] dark:bg-bgDarkDull bg-white px-[18px] xlx:px-[25px] flex justify-end xs:justify-between gap-[20px] xlx:gap-[30px] pt-[15px] xlx:pt-[21px]">
                                                <p className="text-blackDull dark:text-textBlackDull font-semibold max-w-[232px] xs:block hidden">{t("passenger.home.fare.desc")}</p>

                                                <button aria-label={t("aria_label.close")} title={t("aria_label.close")} className="w-fit h-fit rounded-full focus:shadow-[0_0_0_2px_#D7D8DD] hover:shadow-[0_0_0_2px_#D7D8DD] flex items-center justify-center dark:focus:shadow-[0_0_0_2px_#ffffff5e] dark:hover:shadow-[0_0_0_2px_#ffffff5e]" type="button" onClick={() => { dispatch(dataActions.setFareOpen(false)); !!params?.lang ? navigate(`/${params?.lang}`) : navigate(`/`) }}>
                                                    <CloseSec />
                                                </button>
                                            </div>

                                        </div>

                                        {/* curve */}
                                        <div className="absolute left-[-10px] xlx:left-0 bottom-[-17px] xlx:bottom-[-20px]">
                                            <FareBg />
                                        </div>

                                        <div className="absolute top-0 left-0 bottom-0 py-[12px] w-[174px] xlx:w-[200px] flex items-center justify-center xlx:justify-end pr-[12px] xlx:pr-[15px]">
                                            <div className="h-[35px] xlx:h-[40px] px-[18px] bg-primary text-white shadow-[0px_42.444px_61.308px_0px_rgba(0,0,0,0.08)] text-lg font-bold rounded-[20px] flex items-center justify-center whitespace-nowrap min-w-[180px]">{t("passenger.home.fare.title")}</div>
                                        </div>
                                    </div>

                                    <div className="w-full rounded-[20px] xlx:rounded-[25px] !rounded-tr-none dark:bg-bgDarkDull bg-white border dark:border-[#454545] border-[#E4E4E7] h-[calc(100%-55px)] xlx:h-[calc(100%-64px)] border-t-0 px-[20px] py-[20px] xlx:py-[30px] relative">

                                        <p className="text-blackDull dark:text-textBlackDull font-semibold max-w-[232px] xs:hidden block">{t("passenger.home.fare.desc")}</p>

                                        <form onSubmit={(e: any) => getFareDetail(e)} className="flex flex-col w-full xs:pl-[5px] xs:pr-[10px] relative z-[1]">
                                            <div className="flex flex-col mt-[10px] relative gap-[10px]">
                                                <div className='flex flex-col'>
                                                    <SelectComponent
                                                        options={fromSts}
                                                        name="from"
                                                        placeholder={fromStSearch || fromStPh}
                                                        title="From"
                                                        onFocus={() => setFromStPh("")}
                                                        menuIsOpen={fromStOpen}
                                                        onMenuOpen={() => setFromStOpen(true)}
                                                        onMenuClose={() => setFromStOpen(false)}
                                                        onBlur={() => { !!!fromStSearch && setFromStPh(t("passenger.home.fare.form_station")) }}
                                                        value={fromSt}
                                                        onChange={(e: any) => handleFromChange(e)}
                                                        inputValue={fromStSearch}
                                                        onInputChange={handleFromInputChanged}
                                                        arrow={false}
                                                        popup={true}
                                                        components={{ ClearIndicator }}
                                                    />
                                                </div>

                                                <div className='flex items-center w-fit justify-end top-0 bottom-0 absolute right-0 pr-[20px] xlx:pr-[27px]'>
                                                    <button title={t("aria_label.switch_st")} aria-label={t("aria_label.switch_st")} type="button" className='cursor-pointer min-w-[33px] min-h-[33px] xl:min-w-[36px] xl:min-h-[36px] rounded-full border border-[rgba(134,134,134,0.25)] bg-white flex items-center justify-center focus:shadow-[0_0_0_2px_#00376D5e] hover:shadow-[0_0_0_2px_#00376D5e] backdrop-blur-[7px] dark:bg-[rgba(42,44,56,0.30)] dark:focus:shadow-[0_0_0_2px_#ffffff5e] dark:hover:shadow-[0_0_0_2px_#ffffff5e]' onClick={handleAlterChange}>
                                                        <AlterChangeFare />
                                                    </button>
                                                </div>

                                                <div className='flex flex-col'>
                                                    <SelectComponent
                                                        options={toSts}
                                                        name="to"
                                                        placeholder={toStSearch || toStPh}
                                                        title="To"
                                                        onFocus={() => setToStPh("")}
                                                        menuIsOpen={toStOpen}
                                                        onMenuOpen={() => setToStOpen(true)}
                                                        onMenuClose={() => setToStOpen(false)}
                                                        onBlur={() => { !!!toStSearch && setToStPh(t("passenger.home.fare.to_station")) }}
                                                        value={toSt}
                                                        onChange={(e: any) => handleToChange(e)}
                                                        inputValue={toStSearch}
                                                        onInputChange={handleToInputChanged}
                                                        popup={true}
                                                        components={{ ClearIndicator }}
                                                    />
                                                    {(fromErr || toErr) && <div className="flex gap-[6px] items-start mt-[4px]"><Warn />
                                                        <span className="text-danger text-xs font-medium dark:text-darkDanger">{t("passenger.home.fare.err")}</span>
                                                    </div>}
                                                </div>
                                            </div>

                                            <div className="flex items-center justify-between w-full mt-[20px] xlsm:mt-[30px]">
                                                <button type="button" className="flex items-center justify-center gap-[7px] text-danger dark:text-darkDanger dark:focus:shadow-[0_0_0_2px_#FF5B5B5e] dark:hover:shadow-[0_0_0_2px_#FF5B5B5e] font-bold uppercase ml-[22px] disabled:opacity-[0.5] disabled:cursor-not-allowed focus:shadow-[0_0_0_2px_#ff00005e] hover:shadow-[0_0_0_2px_#ff00005e] focus:rounded-[4px] hover:rounded-[4px] disabled:rounded-none disabled:shadow-none disabled:dark:shadow-none py-[1px] px-[6px] text-base leading-snug" disabled={(!!fromStSearch || !!toStSearch) ? false : true} onClick={handleReset}><Reset /> <span className="pt-[2px]">{t("passenger.home.banner.trip_planner.form.reset")}</span></button>
                                                <button type="submit" className="bg-blackDull rounded-[12px] shadow-[0px_0.895px_3.579px_0px_rgba(0,0,0,0.25)] h-[35px] xlx:h-[40px] px-[15px] xs:px-[30px] md:px-[45px] uppercase text-white font-bold flex items-center justify-center w-fit focus:shadow-[0_0_0_3px_#4e566d5e] hover:shadow-[0_0_0_3px_#4e566d5e] dark:focus:shadow-[0_0_0_3px_#ffffff5e] dark:hover:shadow-[0_0_0_3px_#ffffff5e]" disabled={(!submit) ? false : true} onClick={(e: any) => getFareDetail(e)}><h5>{t("passenger.home.fare.btn")}</h5></button>
                                            </div>
                                        </form>

                                        <div className="bg-[#D9D9D9] bg-opacity-[0.1] p-[18px] xlx:py-[22px] xlx:px-[19px] rounded-[20px] xlx:rounded-[25px] flex flex-col gap-[10px] xlx:gap-[15px]  mt-[30px] xlsm:mt-[40px] mb-[60px] xs:mb-[100px]">
                                            <h5 className="uppercase text-primary font-bold">{t("passenger.home.fare.detail.title")}</h5>

                                            <div className="grid grid-cols-1 xxs:grid-cols-2 gap-[9px]">
                                                <div className="p-[10px] xs:p-[12px] xlx:py-[17px] xlx:px-[13px] border dark:border-[#454545] border-[#E5DFFC] dark:bg-bgDarkDull bg-white gap-[10px] flex items-center rounded-[12px] justify-center">
                                                    <div className="bg-[#6592FD] rounded-full min-w-[30px] h-[30px] xlx:min-w-[35px] xlx:h-[35px] flex items-center justify-center">
                                                        <JouFare />
                                                    </div>

                                                    <div className="flex flex-col gap-[2px]">
                                                        <p className="text-[#969696]  dark:text-textBlackDull font-semibold">{t("passenger.home.fare.detail.journey")}</p>
                                                        <h5 className="text-[#171558] dark:text-textBlackDark font-bold">₹ {!!data?.fare_amount ? data?.fare_amount : "0"}</h5>
                                                    </div>
                                                </div>

                                                <div className="p-[10px] xs:p-[12px] xlx:py-[17px] xlx:px-[13px] border dark:border-[#454545] border-[#E5DFFC] dark:bg-bgDarkDull bg-white gap-[10px] flex items-center rounded-[12px] justify-center">
                                                    <div className="bg-[#0FE380] rounded-full min-w-[30px] h-[30px] xlx:min-w-[35px] xlx:h-[35px] flex items-center justify-center">
                                                        <Dis />
                                                    </div>

                                                    <div className="flex flex-col gap-[2px]">
                                                        <p className="text-[#969696] dark:text-textBlackDull font-semibold">{t("passenger.home.fare.detail.distance")}</p>
                                                        <h5 className="text-[#171558] dark:text-textBlackDark font-bold">{!!data?.distance ? parseFloat(data?.distance?.replace(" km", "")).toFixed(2) : "0"} {t("passenger.home.fare.detail.km")}</h5>
                                                    </div>
                                                </div>

                                                {/* <div className="p-[10px] xs:p-[12px] xlx:py-[17px] xlx:px-[13px] border dark:border-[#454545] border-[#E5DFFC] dark:bg-bgDarkDull bg-white gap-[10px] flex items-center rounded-[12px] justify-center">
                                                    <div className="bg-[#43D9DE] rounded-full min-w-[30px] h-[30px] xlx:min-w-[35px] xlx:h-[35px] flex items-center justify-center">
                                                        <Time />
                                                    </div>

                                                    <div className="flex flex-col gap-[2px]">
                                                        <p className="text-[#969696] font-semibold">{t("passenger.home.fare.detail.est_time")}</p>
                                                        <h5 className="text-[#171558] font-bold">{!!data?.estimated_time ? data?.estimated_time?.replace(" min", "") : "0"} {t("passenger.home.fare.detail.min")}</h5>
                                                    </div>
                                                </div> */}

                                            </div>
                                        </div>

                                        <div className="absolute top-0 left-0 right-0 bottom-0 w-full h-full flex items-end justify-end">
                                            <img src={require("../../../assets/images/fare-bg.png")} alt="fare-bg" className="rounded-b-[20px] xlx:rounded-b-[25px] w-full opacity-[0.6] dark:sepia" />
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className="rounded-[20px] border dark:border-[#454545] border-[#E4E4E7] dark:bg-bgDarkDull bg-white shadow-[0px_42.444px_61.308px_0px_rgba(0,0,0,0.08)] p-[9px] w-full xs:w-[320px] xlx:w-[341px] h-fit">
                                <div className="h-[240px] xxs:h-[270px] xlx:h-[296px] rounded-[14px] dark:bg-bgDark bg-[#F0F4F9] w-full">
                                    <img src={require("../../../assets/images/book-ticket.png")} alt="fare-card" className="rounded-[14px] h-[240px] xxs:h-[270px] xlx:h-[296px] w-full" />
                                </div>

                                <div className="pt-[24px] pb-[11px] px-[12px] ">
                                    <h3 className="text-primaryText dark:text-white font-bold ">{t("passenger.home.fare.card.title")}</h3>
                                    <h6 className="text-blackDull dark:text-textBlackDull font-medium mt-[8px]">{t("passenger.home.fare.card.desc")}</h6>

                                    {/* <h6 className="text-primary flex items-center gap-[8px] font-bold mt-[15px]">{t("passenger.home.fare.card.link")} <LinkArr className="fill-primary w-[11px] h-[11px]" /></h6> */}
                                </div>
                            </div>
                        </div>
                    </div >
                </div >}
        </>
    );
};

export default FareCalculator;