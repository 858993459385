import { DotLottie } from '@lottiefiles/dotlottie-web';
import { useEffect } from 'react';

const PageLoader = () => {
    useEffect(() => {
        const canvas = document.querySelector("#page-loader");

        if (!!canvas) {
            const src = "https://lottie.host/ed196209-8a6c-4152-8adf-47ea90e3dece/LcQF0dCnQT.json";
            new DotLottie({
                canvas,
                src,
                loop: true,
                autoplay: true
            } as any);
        }
    }, [])

    return (
        <div className="w-full flex items-center justify-center py-[100px] xlx:py-[145px]">
            {/* <h1 className="text-primary font-black text-2xl max-w-[200px] text-center flex items-center uppercase gap-[2px]">Loading
                <div className="loader">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </h1> */}
            <canvas id="page-loader" className="w-[200px] h-[200px]"></canvas>
        </div>
    );
};

export default PageLoader;