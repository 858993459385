import { useTranslation } from "react-i18next";
import SecondaryBtn from "./SecondaryBtn";
import { DotLottie } from '@lottiefiles/dotlottie-web';
import { useEffect } from "react";

const Error = () => {
    const { t } = useTranslation('common');

    useEffect(() => {
        const canvas = document.querySelector("#error-canvas");

        if (!!canvas) {
            const src = "https://lottie.host/bf0fe25b-3f72-4d47-9552-ce86ebb4b275/S0Fgzv2CQm.json";
            new DotLottie({
                canvas,
                src,
                loop: true,
                autoplay: true
            } as any);
        }
    }, [])

    return (
        <div className="w-full h-full flex flex-col items-center justify-center pb-[60px] xlx:pb-[70px]">
            <canvas id="error-canvas" className="w-[300px] h-[300px] my-[-30px]"></canvas>
            <h3 className="text-blackDull dark:text-textBlackDull font-bold text-center">{t("error.title")}</h3>
            <h6 className="text-blackDull dark:text-textBlackDull font-medium max-w-[575px] text-center mb-[20px] mt-[4px]">{t("error.desc")}</h6>
            <SecondaryBtn onClick={() => window.location.reload()} title={t("error.retry")} css="!rounded-[10px] px-[30px] xlx:px-[40px] font-extrabold uppercase !h-[35px]" />
        </div>
    );
};

export default Error;