const PrimaryTextarea = ({ title, placeholder, value, onBlur, onChange, name, rows, disabled, autoFocus }: any) => {
    const handlePaste = (e: any) => {
        const pastedData = e.clipboardData.getData("text");

        const regex = /(https?:\/\/[^\s]+)/g;
        const regex1 =  /<\/?[^>]+(>|$)/g;

        if (regex.test(pastedData) || regex1.test(pastedData)) {
            e.preventDefault();
        }
    };

    const handleKeyDown = (e: any) => {
        const regex = /[^<>/|\\$#%^&*@]/g;
        const key = e.key;

        if (!regex.test(key)) {
            e.preventDefault();
        }
    };

    return (
        <div className="flex flex-col gap-[4px]">
            <h6 className="text-blackDark dark:text-textBlackDark font-medium">{title}</h6>
            <textarea className="rounded-[6px] hover:bg-white focus:bg-white  bg-[#F5F5F5] border border-[#D7D8DD] py-[8px] xlx:py-[11px] px-[15px] xlx:px-[17px] text-blackDull text-sm leading-snug resize-none placeholder:text-blackDull disabled:opacity-[0.7] disabled:bg-[#F5F5F5] dark:bg-bgDark dark:hover:bg-bgDarkDull dark:focus:bg-bgDarkDull dark:border-[#454545] dark:text-textBlackDull placeholder:dark:text-textBlackDull disabled:dark:bg-bgDark" name={name} placeholder={placeholder} value={value} onBlur={onBlur} onChange={onChange} autoComplete="off" rows={rows} disabled={disabled} onKeyDown={handleKeyDown} autoFocus={autoFocus}
                onPaste={handlePaste} />
        </div>
    );
};

export default PrimaryTextarea;