import Cookies from 'js-cookie';
import { url } from './url';

export const removeCookie = (tenant: any) => {
    if (tenant === url?.subdomain?.recruitment || window?.location?.host?.includes("recruitment")) {
        Cookies.remove('userSessionTimeout', {
            domain: url?.domains?.recruitment,
            path: '/',
            secure: true,
            sameSite: 'None'
        });

        Cookies.remove('userdetail', {
            domain: url?.domains?.recruitment,
            path: '/',
            secure: true,
            sameSite: 'None'
        });

        Cookies.remove('usertoken', {
            domain: url?.domains?.recruitment,
            path: '/',
            secure: true,
            sameSite: 'None'
        });
    } else {
        // session
        Cookies.remove('userSessionTimeout', {
            domain: url?.domains?.main,
            path: '/',
            secure: true,
            sameSite: 'None'
        });

        Cookies.remove('userSessionTimeout', {
            domain: url?.domains?.vigilance,
            path: '/',
            secure: true,
            sameSite: 'None'
        });

        Cookies.remove('userSessionTimeout', {
            domain: url?.domains?.grievance,
            path: '/',
            secure: true,
            sameSite: 'None'
        });

        // user-details
        Cookies.remove('userdetail', {
            domain: url?.domains?.main,
            path: '/',
            secure: true,
            sameSite: 'None'
        });

        Cookies.remove('userdetail', {
            domain: url?.domains?.vigilance,
            path: '/',
            secure: true,
            sameSite: 'None'
        });

        Cookies.remove('userdetail', {
            domain: url?.domains?.grievance,
            path: '/',
            secure: true,
            sameSite: 'None'
        });

        // token
        Cookies.remove('usertoken', {
            domain: url?.domains?.main,
            path: '/',
            secure: true,
            sameSite: 'None'
        });

        Cookies.remove('usertoken', {
            domain: url?.domains?.vigilance,
            path: '/',
            secure: true,
            sameSite: 'None'
        });

        Cookies.remove('usertoken', {
            domain: url?.domains?.grievance,
            path: '/',
            secure: true,
            sameSite: 'None'
        });
    }
}