/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { dataActions } from "../../../shared/store/data-Slice";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useState, useRef, useEffect } from "react";
import { CloseBtn, LeftArr, Warn } from "../svg/AllSvg";
import useAxios from "../../../shared/hooks/use-axios";
import { url } from "../../../shared/utils/url";
import { authActions } from "../../../shared/store/auth-Slice";
import SecondaryBtn from "../form/SecondaryBtn";
import { useNavigate, useParams } from "react-router-dom";
import OtpInput from "react-otp-input";
import { encodeJwt } from "../../../shared/utils/jwt";
import { storeCookie } from "../../../shared/utils/storeCookie";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import { Bounce, ToastContainer } from "react-toastify";
import { errors } from "../../../shared/utils/errors";

const VerifyEmail = ({ role }: any) => {
    const verifyEmailOpen: boolean = useSelector((state: any) => state.data.verifyEmailOpen);
    const theme: any = useSelector((state: any) => state.ui.theme);
    const tenant: string = useSelector((state: any) => state.data.tenant);
    const lang: string = useSelector((state: any) => state.ui.lang);
    const [submit, setSubmit] = useState<boolean>(false);
    const [sendSubmit, setSendSubmit] = useState<boolean>(false);
    const [otp, setOtp] = useState<any>(null);
    const [error, setError] = useState<string>("");
    const [seconds, setSeconds] = useState<number>(299);
    const [progress, setProgress] = useState<number>(0);
    const dispatch = useDispatch();
    const { t } = useTranslation('common');
    const ref: any = useRef();
    const params = useParams();
    const navigate = useNavigate();

    // for seconds count 
    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                let s: number = seconds - 1
                setSeconds(s);
                setProgress(+((s / 300) * 100)?.toFixed())
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target) && role !== "admin") {
                localStorage.removeItem("field_value")
                localStorage.removeItem("auth_type")
                localStorage.removeItem("field")
                localStorage.removeItem("verify_type")
                dispatch(dataActions.setVerifyEmailOpen(false));
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27 && role !== "admin") {
            localStorage.removeItem("field_value")
            localStorage.removeItem("auth_type")
            localStorage.removeItem("field")
            localStorage.removeItem("verify_type")
            dispatch(dataActions.setVerifyEmailOpen(false));
        }
    };

    const handleSend = async () => {
        try {
            setSendSubmit(true)
            let obj: any = {
                type: localStorage.getItem("auth_type"),
                mobile: localStorage.getItem("field_value")
            }

            let api: any = ''
            if (tenant === url?.subdomain?.recruitment) {
                api = await useAxios.post(`/${(!!lang ? lang : "en") + url?.recruitment_send_otp}`, obj);
            } else {
                api = await useAxios.post(`/${(!!lang ? lang : "en") + url?.login_otp}`, obj);
            }

            if (!!api) {
                const res: any = api;
                if (res && res?.status === 200) {
                    setSeconds(299);
                    setProgress(0)
                    setSendSubmit(false)
                    setOtp(null)
                }
            }
        } catch (error: any) {
            errors(error)
            setSendSubmit(false)
            console.error("error while send otp", error);
        }
    }

    const formik: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            phone: localStorage.getItem("field_value"),
        },
        validationSchema: yup.object().shape({
            phone: yup.number().test('len', t("auth.form.validation.wrong_phone"), (val: any) => val && val.toString().length === 10).required(t("auth.form.validation.phone"))
        }),
        onSubmit: async (values: any) => {
            try {
                if (!!!otp) {
                    setError(t("auth.otp_err"));
                    return;
                }
                setSubmit(true)
                let encodedOtp: any = await encodeJwt(otp)
                let obj: any = {};

                if (localStorage.getItem("verify_type") === "profile-verify") {
                    obj.mobile = localStorage.getItem("field_value");
                    obj.otp = encodedOtp;
                    obj.device_type = "web";
                } else {
                    obj.mobile = localStorage.getItem("field_value");
                    obj.type = localStorage.getItem("auth_type");
                    obj.otp = encodedOtp;
                }

                let api: any = ''
                if (localStorage.getItem("verify_type") === "profile-verify") {
                    api = await useAxios.post(`/${(!!lang ? lang : "en") + url?.passenger_verify_profile}`, obj);
                } else {
                    if (tenant === url?.subdomain?.recruitment) {
                        api = await useAxios.post(`/${(!!lang ? lang : "en") + url?.recruitment_verify_otp}`, obj);
                    } else {
                        if (localStorage.getItem("auth_type") === "login") {
                            api = await useAxios.post(`/${(!!lang ? lang : "en") + url?.verify_login_otp}`, obj);
                        } else {
                            api = await useAxios.post(`/${(!!lang ? lang : "en") + url?.verify_passenger_account}`, obj);
                        }
                    }
                }

                if (!!api) {
                    const res: any = api

                    if (res && res?.status === 200) {
                        if (localStorage.getItem("auth_type") === "registration" || localStorage.getItem("auth_type") === "login") {
                            dispatch(authActions.login());
                            dispatch(authActions.userDetail(res?.data?.data))
                            let sessionTimeout: any = (new Date().getTime() + (86400000));
                            dispatch(authActions.userDetail(res?.data?.data));
                            (tenant === url?.subdomain?.recruitment && localStorage.getItem("auth_type") === "registration") && (!!params?.lang ? navigate(`/${params?.lang}/create-profile`) : navigate(`/create-profile`))
                            dispatch(dataActions.setVerifyEmailOpen(false))
                            localStorage.removeItem("field_value")
                            localStorage.removeItem("auth_type")
                            localStorage.removeItem("field")
                            storeCookie(res?.data, sessionTimeout, tenant)
                        } else if (localStorage.getItem("auth_type") === "update_profile") {
                            dispatch(dataActions.setVerifyEmailOpen(false))
                            if (!!params?.lang) {
                                if (window.location.pathname !== `/${params?.lang}/profile` || tenant === url?.subdomain?.recruitment) {
                                    dispatch(dataActions.setVerifyProfile(true))
                                }
                            } else {
                                if (window.location.pathname !== `/profile` || tenant === url?.subdomain?.recruitment) {
                                    dispatch(dataActions.setVerifyProfile(true))
                                }
                            }
                            localStorage.removeItem("field_value")
                            localStorage.removeItem("auth_type")
                            localStorage.removeItem("field")
                            localStorage.removeItem("verify_type")
                        } else {
                            dispatch(dataActions.setVerifyEmailOpen(false))
                            dispatch(dataActions.setNewPasswordOpen(true))
                            localStorage.removeItem("auth_type")
                            localStorage.removeItem("field")
                        }
                        setOtp("")
                        setSubmit(false)
                    }
                }
            } catch (error: any) {
                setSubmit(false)
                errors(error)
                console.error("error while verify email", error);
            }
        }
    });

    const handleBack = () => {
        if (localStorage.getItem("auth_type") === "registration") {
            dispatch(dataActions.setRegisterOpen(true));

        }
        if (localStorage.getItem("auth_type") === "login") {
            dispatch(dataActions.setLoginOpen(true));
        }
        localStorage.removeItem("field_value")
        localStorage.removeItem("auth_type")
        localStorage.removeItem("field")
        localStorage.removeItem("verify_type")
        dispatch(dataActions.setVerifyEmailOpen(false));
    }

    const handleClose = () => {
        localStorage.removeItem("field_value")
        localStorage.removeItem("auth_type")
        localStorage.removeItem("field")
        localStorage.removeItem("verify_type")
        dispatch(dataActions.setVerifyEmailOpen(false));
    }

    return (
        <>
            {verifyEmailOpen &&
                <div className={`fixed inset-0 z-[34] top-0 w-full h-full overflow-y-auto ${role === "admin" ? "bg-white" : "bg-[rgba(171,171,171,0.70)] backdrop-blur-[10px]"}`}>
                    <div className="flex justify-center items-center min-h-screen">
                        <div className={`relative m-[15px] mm:mx-auto dark:bg-bgDarkDull bg-white rounded-[15px] w-full xsm:w-[480px] h-full lg:min-h-[457px] ${role === "admin" ? "shadow-[0px_8px_22px_-6px_rgba(24,39,75,0.12),0px_14px_64px_-4px_rgba(24,39,75,0.12)]" : "shadow-[0px_8px_22px_-6px_rgba(24,39,75,0.12)"}`} ref={ref}>
                            <ToastContainer
                                position="top-center"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light"
                                transition={Bounce}
                            />
                            <form className="flex flex-col relative w-full h-full items-start justify-center p-[20px] xlx:p-[24px] z-[1]">
                                <div className="flex w-full justify-between">
                                    <div className="flex flex-col w-full gap-[8px] xlx:gap-[10px]">
                                        <div className="flex items-center gap-[4px] ml-[-10px]">
                                            <button className=" flex items-center justify-center w-[25px] h-[25px] rounded-full border border-transparent hover:border hover:border-[#ddd] focus:border focus:border-[#ddd] dark:focus:border-[#ffffff5e] dark:hover:border-[#ffffff5e] dark:bg-bgDarkDull bg-white" type="button" onClick={handleBack}>
                                                <LeftArr />
                                            </button>
                                            <h6 className="text-blackDark dark:text-textBlackDark font-medium">{t("passenger.lost_found.report_form.form.back")}</h6>
                                        </div>

                                        <div className="flex flex-col gap-[12px] xlx:gap-[15px]">
                                            <h2 className="font-bold text-primaryText dark:text-white">{lang === "en" ? `Verify Your ${localStorage.getItem("field") === "mobile" ? "Phone" : "Email"}` : lang === "hi" ? `अपना ${localStorage.getItem("field") === "mobile" ? "फ़ोन" : "ईमेल"} वेरीफाई करें` : `तुमचा ${localStorage.getItem("field") === "mobile" ? "फ़ोन" : "ईमेल"} वेरीफाई करा`}
                                            </h2>

                                            <div className="flex flex-col">
                                                <h6 className="text-blackDark dark:text-textBlackDark font-medium">{t("auth.verifyTitle")}</h6>
                                                <h4 className="text-primary font-semibold">{localStorage.getItem("field_value")}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    {role !== "admin" && <button aria-label={t("aria_label.close")} title={t("aria_label.close")} className="rounded-full focus:shadow-[0_0_0_2px_#4e566d5e] hover:shadow-[0_0_0_2px_#4e566d5e] min-w-[23px] h-[23px] flex items-center justify-center bg-[#e4e4e4] dark:bg-[#4c4e5e5e] dark:focus:shadow-[0_0_0_2px_#ffffff5e] dark:hover:shadow-[0_0_0_2px_#ffffff5e]" type="button" onClick={handleClose}>
                                        <CloseBtn />
                                    </button>}
                                </div>

                                <div className="flex flex-col gap-[18px] pt-[20px] lg:pt-[33px] w-full">
                                    <div className="flex items-center w-full">
                                        <div className="flex flex-col gap-[10px] group-input">
                                            <h6 className="text-blackDark dark:text-textBlackDark font-medium">{t("auth.form.field.enter_otp")}</h6>
                                            <OtpInput
                                                value={otp}
                                                onChange={(otp: any) => { setOtp(otp); setError(""); }}
                                                numInputs={6}
                                                shouldAutoFocus={true}
                                                renderInput={(props: any) => <input {...props} />}
                                            />
                                            {!!error && <span className="flex gap-[6px]"><Warn />
                                                <span className="text-danger dark:text-darkDanger text-xs font-medium">{error}</span></span>}
                                        </div>
                                    </div>
                                </div>

                                <SecondaryBtn title="Verify" css="w-full flex items-center justify-center uppercase !rounded-[10px] mt-[30px] font-bold relative" onClick={formik?.handleSubmit} disabled={submit} />

                                {seconds > 0 && <div className="flex items-center justify-center w-full pt-[15px]">
                                    <div className="w-[40px] h-[40px]">
                                        <CircularProgressbarWithChildren value={progress}
                                            styles={buildStyles({
                                                pathColor: "#059DB2",
                                                trailColor: `${theme === "dark" ? "#ffffff5e" : "#00000033"}`,
                                            })} strokeWidth={8}>
                                            <p className="text-blackDark dark:text-textBlackDark font-semibold text-base">
                                                {seconds}
                                            </p>
                                        </CircularProgressbarWithChildren >
                                    </div>
                                </div>}

                                <div className="flex items-center justify-center w-full pt-[15px] mb-[100px] xsm:mb-[139px] xlx:mb-[135px]">
                                    <div className="text-[#606060] dark:text-[#9b9b9b] text-sm leading-snug font-normal">{t("auth.dont_otp")} &nbsp;<button className="text-primary text-sm leading-snug font-semibold focus:underline hover:underline disabled:opacity-[0.5] disabled:cursor-not-allowed disabled:no-underline" disabled={seconds !== 0 || sendSubmit} type="button" onClick={handleSend}>{t("auth.send_again")}</button></div>
                                </div>
                            </form>

                            <div className="absolute top-0 left-0 right-0 bottom-0 flex items-end justify-end">
                                <img src={require("../../../assets/images/reset-password-bg.png")} alt="reset-password-bg" className="rounded-b-[15px] w-full" />
                            </div>
                        </div>
                    </div>
                </div>}
        </>
    );
};

export default VerifyEmail;