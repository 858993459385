/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { dataActions } from "../../../../shared/store/data-Slice";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CloseBlack, Delete, GroupClose, Warn } from "../../../common/svg/AllSvg";
import { useDropzone } from "react-dropzone";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import PrimaryTextarea from "../../../common/form/PrimaryTextarea";
import SecondaryBtn from "../../../common/form/SecondaryBtn";
import useAxios from "../../../../shared/hooks/use-axios";
import { url } from "../../../../shared/utils/url";
import { Bounce, ToastContainer } from "react-toastify";
import { errors } from "../../../../shared/utils/errors";

const ResolveComplaint = () => {
    const { t } = useTranslation('common');
    const resolveComplaint: boolean = useSelector((state: any) => state.data.resolveComplaint);
    const lang: string = useSelector((state: any) => state.ui.lang);
    const [submit, setSubmit] = useState<boolean>(false)
    const [files, setFiles] = useState<any>([]);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [disable, setDisable] = useState<boolean>(false);
    const [err, setErr] = useState<any>("");
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const ref: any = useRef();

    const formik: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            resolution: ''
        },
        validationSchema: yup.object().shape({
            resolution: yup.string().max(250, t("grievance.resolve_complaint.resolution_len")).required(t("grievance.resolve_complaint.resolution")),
        }),
        onSubmit: async (values: any) => {
            //submit
            try {
                setSubmit(true)
                const formData = new FormData();
                formData.append("status", "Resolved");
                formData.append("remarks", values?.resolution);
                files?.length > 0 && formData.append("resolved_attachment", files?.[0]);
                const res: any = await useAxios.put(`/${(!!lang ? lang : "en") + url?.vigilance_resolve_complaint + params?.id}/`, formData);

                if (res && res.status === 200) {
                    dispatch(dataActions.setComplaintApiCall(true));
                    dispatch(dataActions.setResolveComplaint(false));
                    !!params?.lang ? navigate(`/${params?.lang}`) : navigate(`/`)
                }
            } catch (error) {
                errors(error)
                setSubmit(false)
                console.error("error while status change", error);
            }
        }
    });

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                dispatch(dataActions.setResolveComplaint(false));
                !!params?.lang ? navigate(`/${params?.lang}`) : navigate(`/`)
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(dataActions.setResolveComplaint(false))
            !!params?.lang ? navigate(`/${params?.lang}`) : navigate(`/`)
        }
    };

    const simulateChunkUpload = (chunk: any) => {
        // console.log(chunk, "1MB")
        return new Promise((resolve: any, reject: any) => {
            setTimeout(() => {
                resolve();
            }, 1000);
        });
    };

    const { getRootProps, getInputProps, open } = useDropzone({
        onDrop: (acceptedFiles: any) => {
            setErr("")
            if (!acceptedFiles[0]?.name?.match(/\.(PDF|pdf|Doc|doc|docx|DOCX|png|PNG|jpg|JPG|jpeg|JPEG)$/)) {
                setErr(t("passenger.lost_found.report_form.validation.valid_file"));
                return true;
            }

            if (acceptedFiles[0].size > (5 * 1000 * 1024)) {
                setErr(t("passenger.lost_found.report_form.validation.size_err"));
                return;
            }
            setUploadProgress(0);
            setDisable(true)
            setFiles([acceptedFiles[0]])

            let uploadFile = acceptedFiles[0];
            if (uploadFile) {
                const chunkSize = 1024 * 1024; // 1MB chunk size
                const totalChunks = Math.ceil(uploadFile.size / chunkSize);
                let currentChunk = 0;

                const uploadNextChunk = () => {
                    const start = currentChunk * chunkSize;
                    const end = Math.min(start + chunkSize, uploadFile.size);
                    const chunk = uploadFile.slice(start, end);

                    simulateChunkUpload(chunk)
                        .then(() => {
                            currentChunk++;

                            if (currentChunk < totalChunks) {
                                const progress = Math.floor((currentChunk / totalChunks) * 100);
                                setUploadProgress(progress);

                                uploadNextChunk();
                            } else {
                                setUploadProgress(100);
                                setDisable(false)
                            }
                        })
                        .catch((error) => {
                            console.error('Error uploading chunk', error);
                        });
                };

                uploadNextChunk();
            }
        }
    });

    const removeFile = (idx: number) => {
        let arr: any[] = [];
        for (let i = 0; i < files?.length; i++) {
            const el = files[i];

            if (idx !== i) {
                arr.push(el)
            }
        }

        setFiles(arr)
    };

    const thumbs = files?.map((file: any, idx: number) => (
        <div key={idx} className='w-full flex items-center justify-between px-[15px] xlx:px-[19px] border border-[#D7D8DD] rounded-full bg-[#F5F5F5] h-[35px] xlx:h-[40px] gap-[15px]'>
            <Link to={!!file?.url ? file?.url : URL.createObjectURL(file)} download={!!file?.url ? file?.url : URL.createObjectURL(file)} target="_blank" className="text-blackDull text-sm leading-snug font-normal truncate hover:underline focus:underline">{file?.name}</Link>

            {(uploadProgress !== 100 && (idx + 1 === files?.length)) ?
                <div className="flex items-center gap-[5px]">
                    <h1 className="text-blackDull text-sm leading-snug font-normal">{t("passenger.lost_found.report_form.form.uploading")} {uploadProgress}%</h1>
                    <button aria-label={t("aria_label.delete")} title={t("aria_label.delete")} className="min-w-[25px] h-[25px] flex items-center justify-center rounded-full hover:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] focus:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] hover:bg-white focus:bg-white border border-transparent focus:border-[#D7D8DD] hover:border-[#D7D8DD]" onClick={() => removeFile(idx)} type="button">
                        <GroupClose />
                    </button>
                </div> :
                <div className="flex items-center gap-[5px] whitespace-nowrap">
                    <div {...getRootProps()} onClick={(e) => e.stopPropagation}>
                        <input {...getInputProps()} />
                        <button type="button" onClick={open} className="text-blackDull text-sm leading-snug font-semibold disabled:opacity-[0.5] disabled:cursor-not-allowed" disabled={disable}> {t("passenger.lost_found.report_form.form.replace")}</button>
                    </div>
                    <div className="border-r h-[16px] border-primary pl-[5px]"></div>
                    <button aria-label={t("aria_label.delete")} title={t("aria_label.delete")} onClick={() => removeFile(idx)} type="button" className="min-w-[25px] h-[25px] flex items-center justify-center rounded-full hover:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] focus:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] hover:bg-white focus:bg-white border border-transparent focus:border-[#D7D8DD] hover:border-[#D7D8DD]">
                        <Delete />
                    </button>
                </div>
            }
        </div>
    ));

    return (
        <>
            {resolveComplaint &&
                <div className="fixed inset-0 z-[34] top-0 w-full h-full overflow-y-auto bg-[rgba(216,216,216,0.60)] backdrop-blur-[15px]">
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="m-[15px] mm:mx-auto w-full sm:w-[600px] md:w-[665px] h-auto relative bg-white rounded-[10px] shadow-[0px_8px_22px_-6px_rgba(24,39,75,0.12),0px_14px_64px_-4px_rgba(24,39,75,0.12)]" ref={ref}>
                            <ToastContainer
                                position="top-center"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light"
                                transition={Bounce}
                            />

                            <div className="w-full flex items-center justify-between gap-[15px] border-b border-[#DADADA] border-opacity-[0.5] px-[20px] xlx:px-[24px] py-[10px] xlx:py-[11px]">
                                <h5 className="text-primaryText font-semibold">{t("grievance.resolve_complaint.title")}</h5>

                                <button aria-label={t("aria_label.close")} title={t("aria_label.close")} className="w-[25px] h-[25px] rounded-full focus:shadow-[0_0_0_2px_#4145525e] hover:shadow-[0_0_0_2px_#4145525e]" type="button" onClick={() => {
                                    dispatch(dataActions.setResolveComplaint(false))
                                    !!params?.lang ? navigate(`/${params?.lang}`) : navigate(`/`)
                                }}>
                                    <CloseBlack />
                                </button>
                            </div>

                            <form onSubmit={(e: any) => { e.preventDefault(); formik?.handleSubmit(); }} className="py-[16px] px-[22px] flex flex-col gap-[25px] xlx:gap-[30px]">
                                <div className="flex flex-col gap-[4px]">
                                    <PrimaryTextarea title={`${t("grievance.resolve_complaint.add_resolution")}*`} placeholder={t("contact_us.form.start_typing")} name="resolution" rows="4" value={formik?.values?.resolution} onBlur={formik?.handleBlur} onChange={formik?.handleChange} autoFocus={true} />
                                    {formik?.touched?.resolution && formik?.errors?.resolution && <div className="flex gap-[6px] items-start"><Warn />
                                        <span className="text-danger text-xs font-medium">{formik?.errors?.resolution}</span>
                                    </div>}
                                </div>

                                <div className="flex flex-col gap-[4px] w-full">
                                    <h6 className="text-blackDark font-medium">{t("grievance.new_complaint.field.add_attachment")}</h6>

                                    {files?.length === 0 && <div className="flex flex-col gap-[4px]">
                                        <div className="h-[35px] xlx:h-[40px] w-full rounded-full border border-[#D7D8DD] bg-[#F5F5F5] flex items-center justify-between pl-[5px] pr-[18px] xlx:pr-[22px]">
                                            <div {...getRootProps()} onClick={(e) => e.stopPropagation} className={`rounded-full ${(disable || files?.length === 1 || submit) ? "" : "focus:shadow-[0_0_0_3px_#059db25e] hover:shadow-[0_0_0_3px_#059db25e]"}`}>
                                                <input {...getInputProps()} />
                                                <button type="button" tabIndex={-1} onClick={open} className="h-[25px] xlx:h-[30px] flex items-center justify-center w-fit px-[15px] xlx:px-[19px] text-white font-bold text-sm !leading-tight rounded-full bg-primary disabled:opacity-[0.5] disabled:cursor-not-allowed" disabled={disable || files?.length === 1 || submit}> {t("passenger.lost_found.report_form.form.upload_file")}</button>
                                            </div>

                                            <h6 className="text-blackDull font-normal !leading-tight">{t("grievance.resolve_complaint.type")} <span className="text-primaryText font-semibold !leading-tight"> | {t("passenger.lost_found.report_form.form.size")}</span></h6>
                                        </div>
                                        {!!err && <div className="flex gap-[6px] mt-[4px]"><Warn />
                                            <span className="text-danger text-xs font-medium">{err}</span>
                                        </div>}
                                    </div>}
                                </div>

                                {files?.length > 0 && <aside className='w-full flex flex-col gap-[12px]'>{thumbs}</aside>}

                                <div className="flex w-full items-end justify-end">
                                    <SecondaryBtn title={t("contact_us.form.submit")} disabled={submit || disable} onClick={formik.handleSubmit} css="w-fit px-[30px] xlx:px-[40px] flex items-center justify-center !rounded-[6px] font-bold uppercase" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div >}
        </>
    );
};

export default ResolveComplaint;