import { useDispatch, useSelector } from "react-redux";
import { dataActions } from "../../../shared/store/data-Slice";
import { ApplyErr, CloseBtn } from "../../common/svg/AllSvg";
import SecondaryBtn from "../../common/form/SecondaryBtn";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ApplyError = () => {
    const applyError: any = useSelector((state: any) => state.data.applyError);
    const applyData: any = useSelector((state: any) => state.data.applyData);
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation('common');

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(dataActions.setApplyError(false))
        }
    };

    const getErr = () => {
        let arr: any = [];

        let index = 0;
        for (const key in applyData) {
            arr.push(
                <p key={index} className="text-blackDull text-xs font-medium list-disc">{applyData[key]}</p>
            );
            index++;
        }

        return arr;
    };

    return (
        <>
            {applyError &&
                <div className="fixed inset-0 z-[34] overflow-y-hidden top-0 ">
                    <div
                        className="fixed inset-0 w-full h-full bg-[rgba(171,171,171,0.70)] backdrop-blur-[10px]" onClick={() => dispatch(dataActions.setApplyError(false))}
                    ></div>
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="relative m-[15px] mm:mx-auto bg-white dark:bg-bgDarkDull rounded-[10px] w-full md:w-[600px] xlx:w-[713px] shadow-[0px_8px_22px_-6px_rgba(24,39,75,0.12),0px_14px_64px_-4px_rgba(24,39,75,0.12))] pt-[60px] xlx:pt-[72px] pb-[27px]">
                            <div className="absolute top-[18px] right-[22px]">
                                <button aria-label={t("aria_label.close")} title={t("aria_label.close")} className="rounded-full focus:shadow-[0_0_0_2px_#4e566d5e] hover:shadow-[0_0_0_2px_#4e566d5e] min-w-[23px] h-[23px] flex items-center justify-center bg-[#e4e4e4] dark:bg-[#4c4e5e5e] dark:focus:shadow-[0_0_0_2px_#ffffff5e] dark:hover:shadow-[0_0_0_2px_#ffffff5e]" autoFocus={true} onClick={() => dispatch(dataActions.setApplyError(false))}>
                                    <CloseBtn />
                                </button>
                            </div>

                            <div className="w-full h-full flex flex-col items-center justify-center px-[22px]">
                                <ApplyErr />
                                <h3 className="text-danger dark:text-darkDanger font-bold mt-[20px] xlx:mt-[30px] text-center">{t("recruitment.home.apply.error.title")}</h3>
                                <h5 className="text-blackDull dark:text-textBlackDull font-medium text-center mt-[10px] xlx:mt-[15px] max-w-[438px]">{t("recruitment.home.apply.error.desc")}</h5>

                                {(!!applyData) && <div className="border border-[#FFAFAF] rounded-[10px] bg-[#FFFBFB] dark:bg-[#4b3737] dark:border-[#7e5353] p-[10px] md:py-[10px] md:px-[15px] flex flex-col gap-[8px] mt-[19px] lg:mt-[28px] w-full sm:w-[500px]">
                                    <div className="bg-[#FFAFAF] bg-opacity-[0.2] h-[25px] flex items-center justify-center px-[9px] text-danger dark:text-darkDanger text-xs font-semibold w-fit rounded-[9px]">
                                        {t("recruitment.home.apply.error.Criteria")}
                                    </div>

                                    {getErr()}
                                </div>}

                                <div className="flex items-center gap-[10px] mt-[20px] xlx:mt-[25px]">
                                    <button className="border border-primary xlx:h-[40px] h-[35px] flex items-center justify-center rounded-[6px] px-[20px] xlx:px-[29px] w-fit text-primary font-extrabold uppercase text-sm leading-snug focus:shadow-[0_0_0_3px_#059db25e] hover:shadow-[0_0_0_3px_#059db25e]" onClick={() => { dispatch(dataActions.setApplyError(false)); !!params?.lang ? navigate(`/${params?.lang}`) : navigate("/") }}>{t("recruitment.home.cancel")}</button>
                                    <SecondaryBtn title={t("lang.go_back")} css="text-sm leading-snug uppercase !font-extrabold px-[18px] xlx:px-[40px]" onClick={() => dispatch(dataActions.setApplyError(false))} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
        </>
    );
};

export default ApplyError;