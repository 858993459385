/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { dataActions } from '../../../shared/store/data-Slice';
import StaffLayout from './StaffLayout';
import { Route, Routes } from "react-router-dom";
const Login = React.lazy(() => import("../../../pages/corporate/hiring/auth/Login"));
const Home = React.lazy(() => import("../../../pages/corporate/staff/lostFound/Home"));
const NotFound = React.lazy(() => import("../../../pages/common/notFound/NotFound"));

const Staff = () => {
    const adminIsLogin: boolean = useSelector((state: any) => state.auth.adminIsLogin);
    const dispatch = useDispatch();

    useEffect(() => {
        let host: string[] = window.location.hostname.split(".");
        if (host?.length > 0) {
            dispatch(dataActions.setTenant(host?.includes("www") ? host[1] : host[0]))
        }
    }, [window.location.hostname])

    return (
        <>
            <Routes>
                {!adminIsLogin ? <>
                    <Route path="/admin/login" element={<Login />} />
                    <Route path="/:lang/admin/login" element={<Login />} />
                </> :
                    <Route element={<StaffLayout />}>
                        <Route path="/admin" element={<Home />} />
                        <Route path="/:lang/admin" element={<Home />} />
                    </Route>
                }
                <Route path="/:lang/404" element={<NotFound />} />
                <Route path="/404" element={<NotFound />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </>
    );
};

export default Staff;