import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

const Redirect = () => {
    const lang: string = useSelector((state: any) => state.ui.lang);

    return (
        <Routes>
            <Route path="*" element={<Navigate to={`/${lang ? lang : "en"}`} />} />
        </Routes>
    );
};

export default Redirect;