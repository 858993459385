import Select from "react-select";
import useSelectSecondary from "../../../shared/hooks/use-selectSecondary";
import { useSelector } from "react-redux";

const SelectSecondaryComponent = ({ options, placeholder, name, components, menuIsOpen, onMenuOpen, onMenuClose, onInputChange, inputValue, onChange, value, onFocus, onBlur, isSearchable, arrow, err, isDisabled, autoFocus }: any) => {
    const theme: any = useSelector((state: any) => state.ui.theme);
    let styles = useSelectSecondary(arrow, err ? true : false, theme);

    return (
        <Select
            options={options}
            name={name}
            className="w-full select-input"
            placeholder={placeholder}
            styles={styles}
            components={components}
            isSearchable={isSearchable}
            menuIsOpen={menuIsOpen}
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuClose}
            onInputChange={onInputChange}
            inputValue={inputValue}
            onChange={onChange}
            value={value}
            noOptionsMessage={() => null}
            onFocus={onFocus}
            onBlur={onBlur}
            classNamePrefix="react-select"
            isDisabled={isDisabled}
            autoFocus={autoFocus}
        />
    );
};

export default SelectSecondaryComponent;
