import { toast } from 'react-toastify';

export const errors = (error: any) => {
    if (error && (error.response.status === 400 || error.response.status === 403)) {
        if (Array.isArray(error?.response?.data) && error?.response?.data?.length > 0) {
            toast.error(error?.response?.data?.[0])
        } else {
            if (Object.keys(error?.response?.data)?.length > 0) {
                if (Array.isArray(error?.response?.data?.[Object.keys(error?.response?.data)[0]]) && error?.response?.data?.[Object.keys(error?.response?.data)[0]]?.length > 0) {
                    toast.error(error?.response?.data?.[Object.keys(error?.response?.data)[0]]?.toString())
                } else {
                    if (Object.keys(error?.response?.data)[0] === "success") {
                        if (Object.keys(error?.response?.data)?.length > 1) {
                            if (Array.isArray(error?.response?.data?.[Object.keys(error?.response?.data)[1]]) && error?.response?.data?.[Object.keys(error?.response?.data)[1]]?.length > 0) {
                                toast.error(error?.response?.data?.[Object.keys(error?.response?.data)[1]]?.toString())
                            } else {
                                toast.error(error?.response?.data?.[Object.keys(error?.response?.data)[1]])
                            }
                        } else {
                            if (typeof (error?.response?.data?.[Object.keys(error?.response?.data)[1]]) === "string") {
                                toast.error(error?.response?.data?.[Object.keys(error?.response?.data)[1]])
                            }
                        }
                    } else {
                        toast.error(error?.response?.data?.[Object.keys(error?.response?.data)[0]])
                    }
                }
            } else {
                if (typeof (error?.response?.data) === "string") {
                    toast.error(error?.response?.data)
                }
            }
        }
    }
    if (error && error?.response?.status === 404) {
        toast.error("Not Found");
    }
    if (error && error?.response?.status === 500) {
        toast.error("Internal Server Error");
    }
}