import { useDispatch, useSelector } from "react-redux";
import { dataActions } from "../../../shared/store/data-Slice";
import { CloseBtn, ImpNoticeIcon } from "../../common/svg/AllSvg";
import SecondaryBtn from "../../common/form/SecondaryBtn";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ImpNotice = () => {
    const impNotice: any = useSelector((state: any) => state.data.impNotice);
    const lang: string = useSelector((state: any) => state.ui.lang);
    const { t } = useTranslation('common');
    const dispatch = useDispatch();

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(dataActions.setImpNotice(false))
        }
    };

    const handleLink = (item: any) => {
        dispatch(dataActions.setWebOpen(true));
        dispatch(dataActions.setLink(item));
    }

    return (
        <>
            {impNotice &&
                <div className="fixed inset-0 z-40 overflow-y-hidden top-0 ">
                    <div
                        className="fixed inset-0 w-full h-full bg-[rgba(171,171,171,0.70)] backdrop-blur-[10px]" onClick={() => dispatch(dataActions.setImpNotice(false))}
                    ></div>
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="relative m-[15px] mm:mx-auto bg-white dark:bg-bgDarkDull rounded-[10px] w-full md:w-[783px] h-[500px] xlx:h-[615px] shadow-[0px_8px_22px_-6px_rgba(24,39,75,0.12),0px_14px_64px_-4px_rgba(24,39,75,0.12))]">
                            <div className="w-full flex items-center justify-between py-[18px] px-[22px]">
                                <div className="flex items-center gap-[11px]">
                                    <ImpNoticeIcon />
                                    <h4 className="text-danger dark:text-darkDanger font-bold">{t("recruitment.home.disclaimer")}</h4>
                                </div>
                                <button aria-label={t("aria_label.close")} title={t("aria_label.close")} className="rounded-full focus:shadow-[0_0_0_2px_#4e566d5e] hover:shadow-[0_0_0_2px_#4e566d5e] min-w-[23px] h-[23px] flex items-center justify-center bg-[#e4e4e4] dark:bg-[#4c4e5e5e] dark:focus:shadow-[0_0_0_2px_#ffffff5e] dark:hover:shadow-[0_0_0_2px_#ffffff5e]" autoFocus={true} onClick={() => dispatch(dataActions.setImpNotice(false))}>
                                    <CloseBtn />
                                </button>
                            </div>

                            <div className="w-full h-[calc(100%-70px)] flex flex-col justify-between rounded-b-[10px]">
                                {lang === "en" ? <div className="h-full overflow-y-scroll w-full flex flex-col gap-[24px] px-[13px]">
                                    <div className="bg-[#D9D9D9] bg-opacity-[0.21] px-[31px] py-[20px]">
                                        <h5 className="font-bold text-blackDull dark:text-textBlackDull">Beware of Fake Recruitment Offers in Mumbai Metro</h5>

                                        <h6 className="font-semibold text-blackDull dark:text-textBlackDull pt-[20px]">It has come to notice that some unscrupulous elements are trying to deceive candidates/ public by false promises of securing for them a job in MMRC either through influence or by use of unfair/unethical means.
                                        </h6>

                                        <h6 className="font-semibold text-blackDull dark:text-textBlackDull pt-[20px]">Please be advised of the following:</h6>

                                        <ul className="ml-[25px]">
                                            <li className="font-semibold text-blackDull dark:text-textBlackDull text-sm leading-snug list-disc">Recruitment process in MMRC is fully computerized and the selection is purely based on the merit of the candidates.
                                            </li>

                                            <li className="font-semibold text-blackDull dark:text-textBlackDull text-sm leading-snug list-disc"> MMRC has not appointed any agent(s) or recruitment agency(s) for recruitment on its behalf. All recruitment related notifications are uploaded on MMRC’s official website: <Link className="text-primary hover:underline focus:underline font-medium text-sm leading-snug" to="https://www.mmrcl.com/" target="_blank" onClick={(e) => { handleLink("https://www.mmrcl.com/"); e.preventDefault(); }}>https://www.mmrcl.com/</Link> only.</li>

                                            <li className="font-semibold text-blackDull dark:text-textBlackDull text-sm leading-snug list-disc">Public/Candidates are advised not to fall prey to claims made by unscrupulous persons/agencies.</li>

                                            <li className="font-semibold text-blackDull dark:text-textBlackDull text-sm leading-snug list-disc"> Giving or taking bribe is an offense.</li>

                                            <li className="font-semibold text-blackDull dark:text-textBlackDull text-sm leading-snug list-disc">Candidates found indulging in unfair means will be disqualified and shall be liable to legal action against them.</li>
                                        </ul>

                                        <h6 className="font-semibold text-blackDull dark:text-textBlackDull pt-[20px]">In case anyone comes across any such unscrupulous persons/ agencies, please inform the nearest Police Station, with all possible details, so that appropriate action could be taken by the Police.
                                        </h6>

                                        <h6 className="font-semibold text-blackDull dark:text-textBlackDull pt-[20px]">BE A PART OF THE MISSION FOR FAIR & TRANSPARENT RECRUITMENT PROCESS AND JOIN MMRC WITH PRIDE.
                                        </h6>

                                        <h6 className="font-semibold text-blackDull dark:text-textBlackDull pt-[20px]">MMRC, Tel. Nos.: <a href="tel:022-26575155" className="text-primary font-medium">022-26575155</a><span className="text-primary font-medium">/</span><a href="tel:022-26575164" className="text-primary font-medium">022-26575164</a>
                                        </h6>
                                    </div>
                                </div> :
                                    lang === "hi" ?
                                        <div className="h-full overflow-y-scroll w-full flex flex-col gap-[24px] px-[13px]">
                                            <div className="bg-[#D9D9D9] bg-opacity-[0.21] px-[31px] py-[20px]">
                                                <h5 className="font-bold text-blackDull dark:text-textBlackDull">मुंबई मेट्रो में फर्जी भर्ती ऑफर से सावधान रहें</h5>

                                                <h6 className="font-semibold text-blackDull dark:text-textBlackDull pt-[20px]">यह देखने में आया है कि कुछ बेईमान तत्व प्रभाव के माध्यम से या अनुचित/अनैतिक साधनों का उपयोग करके एमएमआरसी में नौकरी दिलाने के झूठे वादे करके उम्मीदवारों/जनता को धोखा देने की कोशिश कर रहे हैं।</h6>

                                                <h6 className="font-semibold text-blackDull dark:text-textBlackDull pt-[20px]">कृपया निम्नलिखित की सलाह लें:</h6>

                                                <ul className="ml-[25px]">
                                                    <li className="font-semibold text-blackDull dark:text-textBlackDull text-sm leading-snug list-disc">एमएमआरसी में भर्ती प्रक्रिया पूरी तरह से कम्प्यूटरीकृत है और चयन पूरी तरह से उम्मीदवारों की योग्यता के आधार पर होता है।</li>

                                                    <li className="font-semibold text-blackDull dark:text-textBlackDull text-sm leading-snug list-disc">एमएमआरसी ने अपनी ओर से भर्ती के लिए किसी एजेंट या भर्ती एजेंसी को नियुक्त नहीं किया है। भर्ती संबंधी सभी सूचनाएं केवल एमएमआरसी की आधिकारिक वेबसाइट:<Link className="text-primary hover:underline focus:underline font-medium text-sm leading-snug" to="https://www.mmrcl.com/" target="_blank" onClick={(e) => { handleLink("https://www.mmrcl.com/"); e.preventDefault(); }}>https://www.mmrcl.com/</Link> अपलोड की जाती हैं।</li>

                                                    <li className="font-semibold text-blackDull dark:text-textBlackDull text-sm leading-snug list-disc">जनता/उम्मीदवारों को सलाह दी जाती है कि वे बेईमान व्यक्तियों/एजेंसियों के दावों का शिकार न बनें।</li>

                                                    <li className="font-semibold text-blackDull dark:text-textBlackDull text-sm leading-snug list-disc">रिश्वत देना या लेना अपराध है।</li>

                                                    <li className="font-semibold text-blackDull dark:text-textBlackDull text-sm leading-snug list-disc">अनुचित साधनों में लिप्त पाए जाने वाले उम्मीदवारों को अयोग्य घोषित कर दिया जाएगा और उनके खिलाफ कानूनी कार्रवाई की जाएगी।</li>
                                                </ul>

                                                <h6 className="font-semibold text-blackDull dark:text-textBlackDull pt-[20px]">यदि कोई भी ऐसे बेईमान व्यक्तियों/एजेंसियों के संपर्क में आता है, तो कृपया सभी संभावित विवरणों के साथ निकटतम पुलिस स्टेशन को सूचित करें, ताकि पुलिस द्वारा उचित कार्रवाई की जा सके।</h6>

                                                <h6 className="font-semibold text-blackDull dark:text-textBlackDull pt-[20px]">निष्पक्ष और पारदर्शी भर्ती प्रक्रिया के मिशन का हिस्सा बनें और गर्व के साथ एमएमआरसी से जुड़ें।</h6>

                                                <h6 className="font-semibold text-blackDull dark:text-textBlackDull pt-[20px]">एमएमआरसी, टेलीफोन नंबर: <a href="tel:022-26575155" className="text-primary font-medium">022-26575155</a><span className="text-primary font-medium">/</span><a href="tel:022-26575164" className="text-primary font-medium">022-26575164</a>
                                                </h6>
                                            </div>
                                        </div>
                                        :
                                        <div className="h-full overflow-y-scroll w-full flex flex-col gap-[24px] px-[13px]">
                                            <div className="bg-[#D9D9D9] bg-opacity-[0.21] px-[31px] py-[20px]">
                                                <h5 className="font-bold text-blackDull dark:text-textBlackDull">मुंबई मेट्रोमध्ये बनावट भरती ऑफर्सपासून सावध रहा</h5>

                                                <h6 className="font-semibold text-blackDull dark:text-textBlackDull pt-[20px]">असे निदर्शनास आले आहे की काही बेईमान घटक उमेदवारांना/जनतेला एमएमआरसी मध्ये नोकरी मिळवून देण्याचे खोटे आश्वासन देऊन त्यांची फसवणूक करण्याचा प्रयत्न करत आहेत.</h6>

                                                <h6 className="font-semibold text-blackDull dark:text-textBlackDull pt-[20px]">कृपया खालील गोष्टींचा सल्ला घ्या:</h6>

                                                <ul className="ml-[25px]">
                                                    <li className="font-semibold text-blackDull dark:text-textBlackDull text-sm leading-snug list-disc">एमएमआरसी मधील भरती प्रक्रिया पूर्णपणे संगणकीकृत आहे आणि निवड पूर्णपणे उमेदवारांच्या गुणवत्तेवर आधारित आहे.</li>

                                                    <li className="font-semibold text-blackDull dark:text-textBlackDull text-sm leading-snug list-disc">एमएमआरसी ने त्यांच्या वतीने भरतीसाठी कोणतेही एजंट(ने) किंवा भर्ती एजन्सी(ची) नियुक्त केलेली नाही. सर्व भरती संबंधित सूचना फक्त एमएमआरसी च्या अधिकृत वेबसाइटवर अपलोड केल्या आहेत: <Link className="text-primary hover:underline focus:underline font-medium text-sm leading-snug" to="https://www.mmrcl.com/" target="_blank" onClick={(e) => { handleLink("https://www.mmrcl.com/"); e.preventDefault(); }}>https://www.mmrcl.com/</Link>.</li>

                                                    <li className="font-semibold text-blackDull dark:text-textBlackDull text-sm leading-snug list-disc">सार्वजनिक/उमेदवारांना बेईमान व्यक्ती/एजन्सींनी केलेल्या दाव्यांना बळी न पडण्याचा सल्ला दिला जातो. </li>

                                                    <li className="font-semibold text-blackDull dark:text-textBlackDull text-sm leading-snug list-disc">लाच देणे किंवा घेणे हा गुन्हा आहे.</li>

                                                    <li className="font-semibold text-blackDull dark:text-textBlackDull text-sm leading-snug list-disc">अनुचित मार्गात गुंतलेले उमेदवार तो अपात्र ठरवेल आणि त्यांच्याविरुद्ध कायदेशीर कारवाईला जबाबदार असेल.</li>
                                                </ul>

                                                <h6 className="font-semibold text-blackDull dark:text-textBlackDull pt-[20px]">अशा कोणत्याही बेईमान व्यक्ती/एजन्सी कोणाला आढळल्यास, कृपया सर्व शक्य तपशिलांसह जवळच्या पोलिस स्टेशनला कळवा, जेणेकरून पोलिसांकडून योग्य ती कारवाई केली जाईल.</h6>

                                                <h6 className="font-semibold text-blackDull dark:text-textBlackDull pt-[20px]">निष्पक्ष आणि पारदर्शक भरती प्रक्रियेच्या मिशनचा एक भाग व्हा आणि अभिमानाने एमएमआरसी मध्ये सामील व्हा.</h6>

                                                <h6 className="font-semibold text-blackDull dark:text-textBlackDull pt-[20px]">MMRC, दूरध्वनी क्रमांक: <a href="tel:022-26575155" className="text-primary font-medium">022-26575155</a><span className="text-primary font-medium">/</span><a href="tel:022-26575164" className="text-primary font-medium">022-26575164</a>
                                                </h6>
                                            </div>
                                        </div>
                                }

                                <div className="px-[31px] flex items-end justify-end py-[24px]">
                                    <SecondaryBtn title={t("recruitment.home.understood")} css="px-[46px] font-semibold" onClick={() => dispatch(dataActions.setImpNotice(false))} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
        </>
    );
};

export default ImpNotice;