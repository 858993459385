/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { dataActions } from "../../../../shared/store/data-Slice";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import useAxios from "../../../../shared/hooks/use-axios";
import { useTranslation } from "react-i18next";
import { CloseBlack, RemoveFile, UserSearch, Warn } from "../../../common/svg/AllSvg";
import { url } from "../../../../shared/utils/url";
import PrimaryTextarea from "../../../common/form/PrimaryTextarea";
import SecondaryBtn from "../../../common/form/SecondaryBtn";
import { errors } from "../../../../shared/utils/errors";
import { Bounce, ToastContainer } from "react-toastify";

const AssignComplaint = () => {
    const assignComplaint: boolean = useSelector((state: any) => state.data.assignComplaint);
    const lang: string = useSelector((state: any) => state.ui.lang);
    const [data, setData] = useState<any[]>([])
    const [com, setCom] = useState<any>(null)
    const [search, setSearch] = useState<string>("")
    const [debouncedValue, setDebouncedValue] = useState<string>(search);
    const [searchFocus, setSearchFocus] = useState<boolean>(false)
    const [user, setUser] = useState<any>(null)
    const [remarksErr, setRemarksErr] = useState<any>("")
    const [assignErr, setAssignErr] = useState<any>("")
    const [submit, setSubmit] = useState<boolean>(false)
    const [open, setOpen] = useState<boolean>(false)
    const [isData, setIsData] = useState<boolean>(false)
    const [remarks, setRemarks] = useState<any>("")
    const { t } = useTranslation('common');
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const ref: any = useRef();
    const ref1: any = useRef();

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                dispatch(dataActions.setAssignComplaint(false));
                !!params?.lang ? navigate(`/${params?.lang}`) : navigate(`/`)
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref1.current && !ref1.current.contains(event.target)) {
                setOpen(false)
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref1]);

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(dataActions.setAssignComplaint(false))
            !!params?.lang ? navigate(`/${params?.lang}`) : navigate(`/`)
        }
    };

    const getUsers = async () => {
        try {
            let api: any = ""
            if (!!debouncedValue) {
                api = await useAxios.get(`/${(!!lang ? lang : "en") + url?.user_search + debouncedValue}/`);
            } else {
                api = await useAxios.get(`/${(!!lang ? lang : "en") + url?.user_search}`);
            }
            if (!!api) {
                const res: any = api;

                if (res && res.status === 200) {
                    res?.data?.results?.length > 0 ? setData(res?.data?.results) : setData([])
                    setIsData(true)
                }
            }
        } catch (error) {
            console.error("error while fetching users", error);
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedValue(search);
        }, 500);

        return () => clearTimeout(timer);
    }, [search]);

    useEffect(() => {
        (!!lang && !!params?.id) && getUsers();
    }, [lang, params?.id, debouncedValue])

    const handleAssign = async () => {
        try {
            if (!!!user && !!!remarks) {
                setRemarksErr(t("grievance.refer_back_complaint.remarks_err"));
                setAssignErr(t("grievance.home.assign.user_err"))
                return
            }

            if (!!!user) {
                setAssignErr(t("grievance.home.assign.user_err"))
                return
            }

            if (!!!remarks) {
                setRemarksErr(t("grievance.refer_back_complaint.remarks_err"));
                return
            }

            setSubmit(true)

            const res: any = await useAxios.put(`/${(!!lang ? lang : "en") + url?.assign_complaint + params?.id}/`, { assigned: true, assigned_user: user?.user_id, assigned_department: user?.department_id, status: com?.status, activity_remarks: remarks });

            if (res && res.status === 200) {
                dispatch(dataActions.setComplaintApiCall(true));
                dispatch(dataActions.setAssignComplaint(false))
                !!params?.lang ? navigate(`/${params?.lang}`) : navigate(`/`)
            }
        } catch (error) {
            errors(error)
            console.error("error while assign user", error);
        }
    }

    const getComplaint = async () => {
        try {
            const res: any = await useAxios.get(`/${(!!lang ? lang : "en") + url?.complaint_details + params?.id}/`);

            if (res && res.status === 200 && res?.data?.length > 0) {
                setCom(res?.data?.[0])
            }
        } catch (error) {
            console.error("error while fetching complaint detail", error);
        }
    }

    useEffect(() => {
        (!!lang && !!params?.id) && getComplaint();
    }, [lang, params?.id])

    useEffect(() => {
        if (!!com && data?.length > 0 && isData) {
            let obj: any = data?.find((item: any) => (com?.assigned_department === item?.department_id && item?.user_id === com?.assigned_user))
            setUser(obj)
        }
    }, [com, isData])

    const handleKeyDown = (e: any) => {
        if (e.key === "Backspace") {
            setUser(null)
        }
    };

    return (
        <>
            {assignComplaint &&
                <div className="fixed inset-0 z-[34] top-0 w-full h-full overflow-y-auto bg-[rgba(216,216,216,0.60)] backdrop-blur-[15px]">
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="m-[15px] mm:mx-auto w-full sm:w-[600px] md:w-[665px] h-auto bg-white rounded-[10px]  relative shadow-[0px_6px_14px_-6px_rgba(24,39,75,0.12),0px_10px_32px_-4px_rgba(24,39,75,0.10)]" ref={ref}>
                            <ToastContainer
                                position="top-center"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light"
                                transition={Bounce}
                            />

                            <div className="w-full flex items-center justify-between gap-[15px] border-b border-[#DADADA] border-opacity-[0.5] px-[20px] xlx:px-[24px] py-[10px] xlx:py-[11px]">
                                <h4 className="text-primaryText font-semibold">{t("grievance.home.assign.assign_this")}</h4>

                                <button aria-label={t("aria_label.close")} title={t("aria_label.close")} className="w-[25px] h-[25px] rounded-full focus:shadow-[0_0_0_2px_#4145525e] hover:shadow-[0_0_0_2px_#4145525e]" type="button" autoFocus onClick={() => {
                                    dispatch(dataActions.setAssignComplaint(false))
                                    !!params?.lang ? navigate(`/${params?.lang}`) : navigate(`/`)
                                }}>
                                    <CloseBlack />
                                </button>
                            </div>

                            <form onSubmit={(e: any) => { e.preventDefault(); handleAssign(); }} className="flex flex-col w-full gap-[15px] px-[20px] py-[15px] ">

                                <div className="flex flex-col gap-[4px]">
                                    <h6 className="text-blackDark dark:text-textBlackDark font-medium">{t("grievance.home.assign.search")}*</h6>
                                    <div className={`bg-[#F5F5F5] border border-[#D7D8DD] h-[42px] px-[12px] xlx:px-[15px] flex items-center gap-[6px] xlx:gap-[9px] rounded-[6px] w-full  ${searchFocus && "bg-white dark:bg-bgDarkDull"} hover:bg-white hover:dark:bg-bgDarkDull relative`} ref={ref1}>
                                        {user && <div className="flex gap-[6px] whitespace-nowrap rounded-[8px] h-[35px] pl-[4px] pr-[8px] border border-[rgba(135,174,119,0.40)] bg-[rgba(135,174,119,0.10)] items-center justify-center cursor-pointer group relative">
                                            <div className="h-[25px] min-w-[25px] flex items-center justify-center rounded-full text-[#015293] text-[12px] font-semibold bg-[#F6FD8D] border border-[#42FD00] !leading-snug uppercase">
                                                {user?.first_name?.charAt(0)}{user?.last_name?.charAt(0)}
                                            </div>
                                            <div className="flex flex-col">
                                                <p className="text-primaryText font-bold text-left !leading-tight">{user?.first_name + " " + user?.last_name}</p>
                                                <p className="text-primaryText font-medium text-left text-[10px] !leading-tight">{user?.department_name}</p>
                                            </div>

                                            <button aria-label={t("aria_label.delete")} title={t("aria_label.delete")} type="button" className="min-w-[16px] min-h-[16px] rounded-[20px] bg-white dark:bg-bgDarkDull group-hover:flex hidden items-center justify-center absolute top-[-6px] right-[-6px] focus:shadow-[0_0_0_1px_#4E566D5e] hover:shadow-[0_0_0_1px_#4E566D5e] dark:focus:shadow-[0_0_0_1px_#ffffff5e] dark:hover:shadow-[0_0_0_1px_#ffffff5e]" onClick={() => setUser(null)}><RemoveFile className="fill-danger dark:fill-darkDanger w-[8px] h-[10px]" /></button>
                                        </div>}
                                        {!!!user && <UserSearch className="xlx:w-[18px] xlx:min-w-[18px] xlx:h-[18px] min-w-[15px] w-[15px] h-[15px] fill-primary" />}
                                        <input type="text" className="text-blackDull font-medium placeholder:text-blackDull text-sm leading-tight w-full bg-transparent" placeholder={!!user ? "" : t("grievance.home.assign.search")} autoComplete="off" value={search} onChange={(e: any) => { !!user ? e.preventDefault() : setSearch(e.target.value) }} onFocus={() => { setSearchFocus(true); setOpen(true); }} onBlur={() => { setSearchFocus(false); }} onKeyDown={handleKeyDown} />

                                        {!!assignErr && <div className="flex gap-[6px] items-start"><Warn />
                                            <span className="text-danger text-xs font-medium">{assignErr}</span>
                                        </div>}

                                        {open && <div className="flex flex-col gap-[4px] bg-white shadow-[0px_6px_14px_-6px_rgba(24,39,75,0.12),0px_10px_32px_-4px_rgba(24,39,75,0.10)] w-full xsm:w-[350px] max-h-[350px] overflow-y-auto absolute top-[50px] left-0 rounded-[10px] py-[10px]">
                                            {data?.length > 0 ? <>
                                                {Array.isArray(data) && data?.map((item: any, index: number) => (
                                                    <button type="button" key={index} className={`${(item?.department_id === user?.department_id && item?.user_id === user?.user_id) ? "bg-[#D9D9D9] bg-opacity-[0.3]" : "hover:bg-[#D9D9D9] hover:bg-opacity-[0.3] focus:bg-[#D9D9D9] focus:bg-opacity-[0.3]"} pl-[20px] py-[10px] flex items-center gap-[15px] relative w-full`} onClick={() => { setUser(item); setAssignErr(""); setOpen(false) }}>
                                                        {(item?.department_id === user?.department_id && item?.user_id === user?.user_id) && <div className="h-full w-[5px] bg-primary absolute left-0 top-0 bottom-0"></div>}
                                                        <div className="h-[35px] xlx:h-[40px] min-w-[35px] xlx:min-w-[40px] flex items-center justify-center rounded-full text-[#015293] text-[12px] xlx:text-[14px] font-semibold bg-[#F6FD8D] border border-[#42FD00] leading-tight uppercase">
                                                            {item?.first_name?.charAt(0)}{item?.last_name?.charAt(0)}
                                                        </div>
                                                        <div className="flex flex-col gap-[2px]">
                                                            <h5 className="text-primaryText font-bold text-left">{item?.first_name + " " + item?.last_name}</h5>
                                                            <p className="text-blackDull font-semibold text-left">{item?.department_name}</p>
                                                        </div>
                                                    </button>
                                                ))}
                                            </> :
                                                <div className="p-[8px] w-full flex items-center justify-center">
                                                    <h5 className="text-danger text-xs font-medium flex gap-[6px] items-start"><Warn /> {t("passenger.lost_found.no_search")}</h5>
                                                </div>
                                            }
                                        </div>}
                                    </div>
                                </div>

                                <div className="flex flex-col gap-[4px]">
                                    <PrimaryTextarea title={`${t("grievance.refer_back_complaint.remarks")}*`} placeholder={t("contact_us.form.start_typing")} name="activity_remarks" rows="4" value={remarks} onChange={(e: any) => { setRemarks(e.target.value); !!e.target.value ? setRemarksErr("") : setRemarksErr(t("grievance.refer_back_complaint.remarks_err")) }} />
                                    {!!remarksErr && <div className="flex gap-[6px] items-start"><Warn />
                                        <span className="text-danger text-xs font-medium">{remarksErr}</span>
                                    </div>}
                                </div>

                                <div className="flex w-full items-end justify-end mt-[15px]">
                                    <SecondaryBtn title={t("grievance.home.assign.assign_btn")} disabled={submit} onClick={handleAssign} css="w-fit px-[30px] xlx:px-[40px] flex items-center justify-center !rounded-[6px] font-bold uppercase" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default AssignComplaint;