import { useDispatch, useSelector } from "react-redux";
import { DotLottie } from '@lottiefiles/dotlottie-web';
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { dataActions } from "../../../shared/store/data-Slice";
import { CloseBtn } from "../svg/AllSvg";

const DownloadLoader = () => {
    const { t } = useTranslation('common');
    const downloadLoader: boolean = useSelector((state: any) => state.data.downloadLoader);
    const downloadFailLoader: boolean = useSelector((state: any) => state.data.downloadFailLoader);
    const dispatch = useDispatch();

    useEffect(() => {
        if (downloadLoader) {
            const canvas = document.querySelector("#download-canvas");

            if (!!canvas) {
                const src = "https://lottie.host/e4c881da-fbb3-455b-ae75-f437cd478b84/S04wZ0LR56.json";
                new DotLottie({
                    canvas,
                    src,
                    loop: true,
                    autoplay: true
                } as any);
            }
        }
    }, [downloadLoader])

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27 && downloadFailLoader) {
            dispatch(dataActions.setDownloadLoader(false))
            dispatch(dataActions.setDownloadFailLoader(false))
        }
    };

    return (
        <>
            {downloadLoader &&
                <div className="fixed inset-0 z-[40] overflow-y-hidden top-0 ">
                    <div
                        className="fixed inset-0 w-full h-full bg-[rgba(216,216,216,0.60)] backdrop-blur-[15px]" onClick={() => {
                            if (downloadFailLoader) {
                                dispatch(dataActions.setDownloadLoader(false))
                                dispatch(dataActions.setDownloadFailLoader(false))
                            }
                        }}
                    ></div>
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="m-[15px] md:mx-auto dark:bg-bgDarkDull bg-white rounded-[12px] w-[275px] ss:w-[320px] h-auto sm:w-[400px] relative p-[30px] shadow-[0px_20px_24px_-4px_rgba(16,24,40_0.08),0px_8px_8px_-4px_rgba(16,24,40,0.03)] flex items-center justify-center flex-col">
                            {downloadFailLoader && <button aria-label={t("aria_label.close")} title={t("aria_label.close")} className="absolute top-[8px] right-[8px] mm:top-[15px] mm:right-[15px] rounded-full focus:shadow-[0_0_0_2px_#4e566d5e] hover:shadow-[0_0_0_2px_#4e566d5e] min-w-[23px] h-[23px] flex items-center justify-center bg-[#e4e4e4] dark:bg-[#4c4e5e5e] dark:focus:shadow-[0_0_0_2px_#ffffff5e] dark:hover:shadow-[0_0_0_2px_#ffffff5e]" onClick={() => {
                                dispatch(dataActions.setDownloadLoader(false))
                                dispatch(dataActions.setDownloadFailLoader(false))
                            }}>
                                <CloseBtn className="min-w-[23px]" />
                            </button>}

                            {downloadFailLoader ?
                                <>
                                    <div className="min-w-[105px] h-[105px] flex items-center justify-center rounded-full bg-secDarkRed">
                                        <img src={require("../../../assets/images/service-fail.gif")} alt="download-fail" className="w-[81px] h-[81px]" />
                                    </div>
                                    <h3 className="text-blackDull dark:text-textBlackDull font-bold text-center mt-[10px]">{t("aria_label.download_fail")}</h3>
                                    <h6 className="text-blackDull dark:text-textBlackDull font-medium text-center mt-[2px]">{t("aria_label.download_fail_desc")}</h6>
                                </> :
                                <>
                                    <canvas id="download-canvas" className="w-[300px] h-[150px]"></canvas>
                                    <h3 className="text-blackDull dark:text-textBlackDull font-bold text-center mt-[10px]">{t("aria_label.download")}</h3>
                                    <h6 className="text-blackDull dark:text-textBlackDull font-medium text-center mt-[2px]">{t("aria_label.download_desc")}</h6>
                                </>}
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default DownloadLoader;